import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const FoodPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['learn', 'play']}
    pageName="food_page"
    customNavigationPath="/words/food"
  />
)

export default FoodPage
