import React from 'react'
import LearnPage from '../../../../components/LearnPage'
import { getSmallCountryImageUrl } from '../../../../pages/geography/locations/util'

const LearnFamousLocationsPage = () => (
  <LearnPage
    parentPage="locations"
    grandParentPage="geography"
    pageName="learn_famous_locations"
    getImageUrl={getSmallCountryImageUrl}
  />
)

export default LearnFamousLocationsPage
