import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const DivisionsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['beginner', 'easy', 'medium', 'hard', 'learn']}
    pageName="divisions_page"
    customNavigationPath="/mathematics/divisions"
  />
)

export default DivisionsPage
