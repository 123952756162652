import React from 'react'
import { changePathAction } from '../redux/actions/actions'

const AnnyangNotSupportedMenuButton = ({ dispatch, common }) => (
  <button
    className="annyang-not-supported-menu-button"
    onClick={() => dispatch(changePathAction('/menu'))}>
    {common('menu')}
  </button>
)

export default AnnyangNotSupportedMenuButton
