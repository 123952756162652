import React, { Component } from 'react'
import { connect } from 'react-redux'
import TopicNavigationPage from '../components/TopicNavigationPage'
import ImageChoiceButton from '../components/ImageChoiceButton'
import FreeVersionBanner from '../components/FreeVersionBanner'
import ReadOutLoudRoboticVoiceNotice from '../components/ReadOutLoudRoboticVoiceNotice'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import {
  genderSupportedLanguages,
  supportedGenders,
  soundLevelsMap,
  supportedSoundLevels,
  findSpokenFromList,
  lngGendersMap,
  common
} from '../lib/languagesUtils/languageUtil'
import { isUsingAnnyang } from '../lib/speechToTextUtils/speechToText'
import { updateGenderAction, updateSoundLevelAction } from '../redux/actions/actions'

/* LATER
 - settings data saved in cookie or localStorage (per browser))
 - Setting toggle ideas:
 1. wrongs answered questions can be repeated in the list
*/

class SettingsPage extends Component {
  componentDidMount() {
    this.handleGenderClickChange = this.handleGenderClickChange.bind(this)
    this.handleSoundLevelChange = this.handleSoundLevelChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { transcript, dispatch, lngCode } = this.props

    if (prevProps.transcript !== transcript) {
      const foundGender = findSpokenFromList(lngCode, transcript, supportedGenders)
      if (foundGender) {
        dispatch(updateGenderAction(lngGendersMap[lngCode][foundGender]))
      }

      const foundSoundLevel = findSpokenFromList(lngCode, transcript, supportedSoundLevels)
      if (foundSoundLevel) {
        dispatch(updateSoundLevelAction(soundLevelsMap[lngCode][foundSoundLevel]))
      }
    }
  }

  handleGenderClickChange(type) {
    const { dispatch } = this.props
    dispatch(updateGenderAction(type))
  }

  handleSoundLevelChange(level) {
    const { dispatch } = this.props
    dispatch(updateSoundLevelAction(level))
  }

  render() {
    const {
      user,
      isChrome,
      isRoboticVoiceNoticeVisible,
      language,
      gender,
      soundLevel,
      lngCode,
      dispatch
    } = this.props
    const isGenderChangeSupported = genderSupportedLanguages.includes(language)

    return (
      <TopicNavigationPage
        navigationCommands={['language', 'about']}
        pageName="settings_page"
        extraTopicsInstructions={
          isGenderChangeSupported ? ['gender', 'sound_level'] : ['sound_level']
        }
        contentCommands={
          lngCode == 'en' ? supportedGenders[lngCode].filter(sg => sg !== gender) : []
        }
        borderRadius={0.36}
        SEODescription="E=MC² - Edit My Choices² - Via Automatic Speech Recognition Commands and Dictation, Speak to the app & Choose Voice Gender, Sound Levels, Change Language & more">
        {translate => (
          <>
            {isGenderChangeSupported && isChrome && (
              <>
                <h5>{translate('voice_gender')}</h5>
                <div className="image-choice-images-wrapper">
                  {supportedGenders['en'].map((type, index) => (
                    <ImageChoiceButton
                      key={type}
                      value={type}
                      index={index}
                      altName="gender"
                      isSelected={type === gender}
                      areSmallImages={true}
                      imageText={`${common(`genders.${type}`)}${type === gender ? ' ✓' : ''}`}
                      onClick={() => (type !== gender ? this.handleGenderClickChange(type) : {})}
                      srcUrl={`images/gender-settings/${type}.webp`}
                    />
                  ))}
                </div>
              </>
            )}
            <>
              <h5>{translate('sound_level')}</h5>
              <div className="image-choice-images-wrapper">
                {['on', 'partial', 'off'].map((level, index) => (
                  <ImageChoiceButton
                    key={`sound-level-${level}`}
                    value={level}
                    index={index}
                    altName="sound-level"
                    isSelected={level === soundLevel}
                    areSmallImages={true}
                    imageText={`${common(`sound-levels.${level}`)}${
                      level === soundLevel ? ' ✓' : ''
                    }`}
                    onClick={() => (level !== soundLevel ? this.handleSoundLevelChange(level) : {})}
                    srcUrl={`images/sound-settings/${level}.webp`}
                  />
                ))}
              </div>
            </>

            {isUsingAnnyang(user) && <FreeVersionBanner common={common} />}

            {isRoboticVoiceNoticeVisible && soundLevel !== 'off' && (
              <ReadOutLoudRoboticVoiceNotice dispatch={dispatch} common={common} />
            )}
          </>
        )}
      </TopicNavigationPage>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(SettingsPage)
