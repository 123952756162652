import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnSubtractionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['beginner', 'easy', 'medium', 'subtractions', 'mathematics']}
    pageName="learn_subtractions_page"
    topicNavigationPath="mathematics/subtractions/learn"
    borderRadius={0.65}
    canonicalPath="/mathematics"
  />
)

export default LearnSubtractionsPage
