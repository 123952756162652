import React from 'react'
import { baseUrl } from '../lib/_processUtil'
import { withTranslation } from 'react-i18next'
import { comingSoonTopics, newTopics } from '../lib/dataUtil'
import {
  capitalizeWord,
  getEnCommandName,
  isDifferentlyStyledTopic,
  getTopic,
  isLearnMathematicsPage,
  isMathsLearnClick,
  isLockedTopic
} from '../lib/methodsUtil'
import { common } from '../lib/languagesUtils/languageUtil'
import { changePathAction } from '../redux/actions/actions'
import { createBorderRadius } from '../lib/methodsUtil'
import ImageWithDimensions from './ImageWithDimensions'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'

const NavigationButtons = ({
  user,
  pageName,
  lngCode,
  customNavigationCommands,
  isOverOneDayShare,
  dispatch,
  otherButton
}) => {
  const isComingSoonTopic = isDifferentlyStyledTopic(comingSoonTopics)
  const isNewTopic = isDifferentlyStyledTopic(newTopics)

  const comingSoonBadge = (
    <div style={createBorderRadius(0.2)} className="navigation-button-coming-soon-topic-badge">
      {common('coming_soon')}
    </div>
  )

  const newBadge = (
    <div style={createBorderRadius(0.2)} className="navigation-button-new-topic-badge">
      {common('new')}
    </div>
  )

  const isTopicType = (command, name, path) =>
    command !== common(name) && customNavigationCommands[command] === path

  return (
    <div className="navigation-commands">
      {otherButton}
      {Object.keys(customNavigationCommands).map(command => {
        const path = customNavigationCommands[command]
        const isNeedDonation = isTopicType(command, 'support', '/support')
        const isComingSoon = isComingSoonTopic(command)
        const enCommand = getEnCommandName(lngCode, command)
        let isLockedCapitals = false
        if (enCommand === 'capitals') {
          isLockedCapitals = !isLockedTopic(user, path) ? false : isOverOneDayShare
        }
        const isLockedPremiumTopic = isTopicType(command, 'shop', '/shop')

        return (
          <button
            style={createBorderRadius(0.7)}
            key={command}
            className={
              isLockedCapitals
                ? 'navigation-button-need-sharing-style'
                : isNeedDonation
                  ? 'navigation-button-need-donation-style'
                  : isComingSoon
                    ? 'navigation-button-coming-soon-style'
                    : ''
            }
            disabled={isComingSoon}
            onClick={() => {
              dispatch(changePathAction(path))
              if (pageName && isLearnMathematicsPage(pageName) && isMathsLearnClick(command)) {
                sendAmplitudeEvent('Learning Page Visits', {
                  topic: getTopic(pageName),
                  action: 'click'
                })
              }
            }}>
            <span className="menu-button-icon-wrapper">
              <ImageWithDimensions
                alt={`${command} icon`}
                className="menu-button-icon"
                src={`${baseUrl}/images/icons/${isLockedPremiumTopic ? 'lock' : enCommand}.webp`}
              />
              {isNeedDonation && (
                <ImageWithDimensions
                  alt="support icon"
                  className="menu-button-support-icon"
                  src={`${baseUrl}/images/icons/support.webp`}
                />
              )}
              {isLockedCapitals && (
                <ImageWithDimensions
                  alt="social key icon"
                  className="menu-button-social-key-icon"
                  src={`${baseUrl}/images/icons/key.webp`}
                />
              )}
            </span>
            <span className="menu-button-command-wrapper">{capitalizeWord(command)}</span>
            {isComingSoon ? comingSoonBadge : isNewTopic(command) ? newBadge : null}
          </button>
        )
      })}
    </div>
  )
}

export default withTranslation()(NavigationButtons)
