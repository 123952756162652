import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const AdditionsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['beginner', 'easy', 'medium', 'hard', 'learn']}
    pageName="additions_page"
    customNavigationPath="/mathematics/additions"
  />
)

export default AdditionsPage
