import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const SentencesReadingPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['easy', 'medium', 'hard']}
    pageName="sentences_reading_page"
    customNavigationPath="/reading/read-sentences"
  />
)

export default SentencesReadingPage
