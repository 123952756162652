// import { wasPathChanged } from '../router/routerUtil'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import '../styles/scss/App.scss'
import { auth, db } from '../database/firebase.config'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import NavigationButtons from '../components/NavigationButtons'
import ImageWithDimensions from '../components/ImageWithDimensions'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import { translateFrom, common, makeWhereIAmText } from '../lib/languagesUtils/languageUtil'
import { startRecording, stopRecording } from '../lib/speechToTextUtils/speechToText'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { changePathAction, updateIsListeningAction, setUserAction } from '../redux/actions/actions'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  createRoundedBorderRadius,
  isLoggedUser,
  isPaidUser,
  isValidEmailFormat
} from '../lib/methodsUtil'
import { isReadOutLoudDisabled, baseUrl } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'
import {
  userDataDefaultProperties,
  googleSignUp,
  facebookSignUp,
  appleSignUp,
  twitterSignUp,
  microsoftSignUp
} from '../lib/signUpUtil'

// SSS - 'You need to agree to our Terms and Conditions before registering.',

// SSS - Remove buttons hover state and opacity on mobile

// SSS - on pages, Hide sign up link if a user is already logged

// SSS - Add a captcha?
// "Couldn't verify that you're human, please try again.",
// https://github.com/dozoisch/react-google-recaptcha?tab=readme-ov-file // seems to be only for prod / live
// https://www.npmjs.com/package/react-simple-captcha

// SSS - Copy to SocialSignUp when finished and remove social in this one.

const navigationCommands = ['menu']

const translate = translateFrom('sign_up_page')
const translateError = translateFrom('errors')

class SignupPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userData: null,
      username: '',
      usernameError: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      passwordConfirm: '',
      passwordConfirmError: '',
      genericError: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
    this.checkForUsernameError = this.checkForUsernameError.bind(this)
    this.checkForEmailError = this.checkForEmailError.bind(this)
    this.checkForPasswordError = this.checkForPasswordError.bind(this)
    this.checkForPasswordConfirmError = this.checkForPasswordConfirmError.bind(this)
  }

  componentDidMount() {
    const { lngCode, user, dispatch } = this.props
    if (isLoggedUser(user)) {
      dispatch(changePathAction('/profile'))
    } else {
      dispatch(updateIsListeningAction(false))
      startRecording(user)({ user, dispatch, navigationCommands, lngCode })
    }
  }

  componentDidUpdate(prevProps) {
    const { user: prevUser } = prevProps
    const { user, dispatch } = this.props
    const { userData } = this.state

    if (isLoggedUser(userData)) {
      dispatch(setUserAction(userData))
      this.setState({ userData: {} })
      // SSS - on the profile page "welcome to MiniE" (if registration time is less 24 hours ago), this is the profile page!
      // react-toastify
    }

    if (!isLoggedUser(prevUser) && isLoggedUser(user)) {
      dispatch(changePathAction('/profile'))
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleClearForm() {
    this.setState({
      username: '',
      usernameError: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      passwordConfirm: '',
      passwordConfirmError: '',
      genericError: ''
    })
  }

  async checkForUsernameError() {
    const { username } = this.state
    if (username && username.length > 30) {
      this.setState({ usernameError: 'username_too_long' })
    } else {
      const usernamesRef = doc(db, 'usernames', username)
      const usernameDoc = await getDoc(usernamesRef)
      this.setState({ usernameError: usernameDoc.exists() ? 'username_already_in_use' : '' })
    }
  }

  async checkForEmailError() {
    const { email } = this.state
    if (!!email.length && !isValidEmailFormat(email)) {
      this.setState({ emailError: 'email_invalid' })
    } else {
      const emailRef = doc(db, 'emails', email)
      const emailDoc = await getDoc(emailRef)
      this.setState({ emailError: emailDoc.exists() ? 'email_already_registered' : '' })
    }
  }

  checkForPasswordError() {
    const { password, passwordConfirm } = this.state
    this.setState({
      passwordError: password.length > 0 && password.length < 8 ? 'password_too_short' : ''
    })
    if (passwordConfirm) {
      this.setState({
        passwordConfirmError:
          password && passwordConfirm && password === passwordConfirm
            ? ''
            : 'password_confirm_error'
      })
    }
  }

  checkForPasswordConfirmError() {
    const { password, passwordConfirm } = this.state
    this.setState({
      passwordConfirmError:
        password && passwordConfirm && password === passwordConfirm ? '' : 'password_confirm_error'
    })
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { username, email, password } = this.state

    try {
      // Create the new user
      const { user } = await createUserWithEmailAndPassword(auth, email, password)

      const user_id = user.uid
      const userDataRef = doc(db, 'users', user_id)
      const userDoc = await getDoc(userDataRef)

      let userData
      if (!userDoc.exists()) {
        userData = {
          userId: user_id,
          username, // SSS - need to sanitize / profanity library?
          email,
          ...userDataDefaultProperties
        }
        // adds in the usernames collection so no other user can use that username
        const usernamesRef = doc(db, 'usernames', username)
        await setDoc(usernamesRef, { username })

        // adds in the emails collection so no other user can use that email
        const emailRef = doc(db, 'emails', email)
        await setDoc(emailRef, { email })

        // adds the default data in the userData collection
        await setDoc(userDataRef, userData).then(() => {
          this.setState({ userData })
        })
      }
    } catch (error) {
      this.setState({ genericError: error && error.message })
    }
  }

  render() {
    const { user, path, soundLevel, language, lngCode, transcript, dispatch, isAnnyangSupported } =
      this.props
    const {
      username,
      usernameError,
      email,
      emailError,
      password,
      passwordError,
      passwordConfirm,
      passwordConfirmError,
      genericError
    } = this.state

    const disableSubmit =
      !username ||
      usernameError !== '' ||
      !email ||
      emailError !== '' ||
      !password ||
      passwordError !== '' ||
      !passwordConfirm ||
      passwordConfirmError !== '' ||
      genericError !== ''
    const disableClear = !username && !email && !password && !passwordConfirm

    const customNavigationCommands = {}
    navigationCommands.forEach(command => {
      customNavigationCommands[common(command).toLowerCase()] = `/${command.replace(/_/g, '-')}`
    })

    const intro = translate('intro')
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? intro : makeWhereIAmText[lngCode]({ title: common('contact') }),
        dispatch,
        language,
        clearTranscriptOnEnd: true,
        navigationCommands: customNavigationCommands,
        user
      })
    }

    const callback = async (userDataRef, userData) => {
      await setDoc(userDataRef, userData).then(() => {
        this.setState({ userData })
      })
    }

    const errorCallback = error => {
      this.setState({ genericError: error && error.message })
    }

    const emailAlreadyInUseCallback = () => {
      this.setState({ emailError: 'email_already_registered' })
    }

    return (
      <div className="signup-page top-navigation">
        <SEO
          description="E=MC² - Embrace the Mission and the call² - Have fun and play games on Words, Maths, Geography, Reading, Astronomy, History..."
          path={path}
        />

        <div className="navigation-box form-navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} intro={intro} />

          <div className="signup-page_form_wrappers">
            <form
              className="form signup-page_manual_sign_up_wrapper"
              name="contact"
              onSubmit={this.handleSubmit}>
              <h2>Create An Account</h2>
              <div className="form-fields">
                <div className="form-column">
                  <div className="form-input-title">{common('username')}:</div>
                  <input
                    name="username"
                    type="text"
                    onChange={this.handleInputChange}
                    value={username}
                    style={createBorderRadius(0.1)}
                    className="form-md-input-field"
                    onBlur={this.checkForUsernameError}
                  />
                  {usernameError === 'username_too_long' && (
                    <div className="form-error">{translateError('username_too_long')}</div>
                  )}
                  {usernameError === 'username_already_in_use' && (
                    <div className="form-error">
                      {translateError('username_already_registered')}
                    </div>
                  )}
                  <div className="form-input-title">{common('email')}:</div>
                  <input
                    name="email"
                    type="email"
                    onChange={this.handleInputChange}
                    value={email}
                    style={createBorderRadius(0.4, true)}
                    className="form-md-input-field"
                    onBlur={this.checkForEmailError}
                  />
                  {emailError === 'email_invalid' && (
                    <div className="form-error">{translateError('email_invalid')}</div>
                  )}
                  {emailError === 'email_already_registered' && (
                    <div className="form-error">{translateError('email_already_registered')}</div>
                  )}
                  <div className="form-input-title">{common('password')}:</div>
                  <input
                    type="password"
                    name="password"
                    onChange={this.handleInputChange}
                    value={password}
                    style={createBorderRadius(0.7)}
                    className="form-md-input-field"
                    onBlur={this.checkForPasswordError}
                  />
                  {passwordError === 'password_too_short' && (
                    <div className="form-error">{translateError('password_too_short')}</div>
                  )}
                  <div className="form-input-title">{common('password_confirm')}:</div>
                  <input
                    name="passwordConfirm"
                    type="password"
                    onChange={this.handleInputChange}
                    value={passwordConfirm}
                    style={createBorderRadius(0.9, true)}
                    className="form-md-input-field"
                    onBlur={this.checkForPasswordConfirmError}
                  />
                  {passwordConfirmError && (
                    <div className="form-error">{translateError('passwords_not_matching')}</div>
                  )}
                </div>
              </div>

              <a href={`${baseUrl}/privacy-policy`} target="blank">
                Privacy Policy
              </a>
              <a href={`${baseUrl}/terms-of-use`} target="blank">
                Terms of use
              </a>

              <div className="form-buttons">
                <button
                  type="submit"
                  disabled={disableSubmit}
                  className={disableSubmit ? 'disabled' : ''}
                  style={createBorderRadius(0.9, true)}>
                  {common('send')}
                </button>
                <button
                  onClick={this.handleClearForm}
                  disabled={disableClear}
                  className={disableClear ? 'disabled' : ''}
                  style={createBorderRadius(0.1)}>
                  {common('clear')}
                </button>
              </div>
            </form>

            <div className="signup-page_sign_up_separator">
              <div className="signup-page_sign_up_separator-pre-line" />
              <h4 className="signup-page_sign_up_separator-or">or</h4>
              <div className="signup-page_sign_up_separator-line" />
            </div>

            <div className="form signup-page_social_sign_up_wrapper">
              <h2>Sign up with...</h2>
              <button className="home-box-button" style={createRoundedBorderRadius(0.7)}>
                <ImageWithDimensions
                  alt="Google sign up"
                  src={`${baseUrl}/images/social-icons/google.svg`}
                  onClick={() => {
                    googleSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                  }}
                />
                Google
              </button>

              <button className="home-box-button" style={createRoundedBorderRadius(0.14, true)}>
                <ImageWithDimensions
                  alt="Facebook sign up"
                  src={`${baseUrl}/images/social-icons/facebook.svg`}
                  onClick={() => {
                    facebookSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                  }}
                />
                Facebook
              </button>

              <button className="home-box-button" style={createRoundedBorderRadius(0.52)}>
                <ImageWithDimensions
                  alt="X sign up"
                  src={`${baseUrl}/images/social-icons/x.svg`}
                  onClick={() => {
                    twitterSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                  }}
                />
                X
              </button>

              <button className="home-box-button" style={createRoundedBorderRadius(0.95, true)}>
                <ImageWithDimensions
                  alt="Apple sign up"
                  src={`${baseUrl}/images/social-icons/apple.svg`}
                  onClick={() => {
                    appleSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                  }}
                />
                Apple
              </button>

              <button className="home-box-button" style={createRoundedBorderRadius(0.32)}>
                <ImageWithDimensions
                  alt="Microsoft sign up"
                  className="microsoft-icon"
                  src={`${baseUrl}/images/social-icons/microsoft.svg`}
                  onClick={() => {
                    microsoftSignUp(callback, errorCallback, emailAlreadyInUseCallback)
                  }}
                />
                Microsoft
              </button>
            </div>
          </div>

          <div className="signup-page-hr"></div>

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}

          {path !== '/sign-up' && <Navigate to={path} replace />}
        </div>
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(SignupPage))
