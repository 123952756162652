import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const ReadingPage = () => (
  <TopicNavigationPage
    // Using read_words instead of words because each item needs to be unique; the icon for example uses the name + .webp
    navigationCommands={['read_words', 'read_sentences', 'read_stories']}
    pageName="reading_page"
    topicNavigationPath="reading"
    borderRadius={0.55}
    SEODescription="E=MC² - Enunciation Made Constructive² - Speech recognition: Play, Learn how to read & speak & have fun with words, phrases, vocabulary, sentences and stories"
  />
)

export default ReadingPage
