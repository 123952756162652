import { removeComingSoonTopics, updateWithRedirectPaths } from '../methodsUtil'
import { mainCommandpathMap, extendedCommandPathMap } from '../commandPathMaps'
import { commonTranslateArray, addAcceptedSpellingCommands } from '../languagesUtils/languageUtil'

// Main navigation accepts 1 spelling as command, and is used for buttons alone for example.
// { drapeau: "/geography/flags" } BUT drapeaux is not mapped.
const createMainNavigationCommands = ({
  user = {},
  navigationCommands = ['menu'],
  lngCode = 'en',
  withComingSoon = false,
  temporarilyUnlocked = false
}) => {
  // With navigationCommands = ['capitals', 'flags', 'locations', 'whatever', 'menu'] and comingSoonTopics = ['whatever']
  // filteredNavigationCommands = ['capitals', 'flags', 'locations', 'menu']
  let filteredNavigationCommands = withComingSoon
    ? navigationCommands
    : removeComingSoonTopics(navigationCommands)

  // translatedNavigationCommands = ['capitales', 'drapeaux', 'localisations', 'menu']
  const translatedNavigationCommands = commonTranslateArray(filteredNavigationCommands)

  // ...then commandpathMap = { capitales: '/geography/capitals', drapeaux: '/geography/flags', localisations: '/geography/locations', menu: '/menu' }
  let commandpathMap = {}
  translatedNavigationCommands.forEach(command => {
    commandpathMap[command] = mainCommandpathMap[lngCode][command]
  })

  // ...then, to handle additional commands for sub-topic pages, like if we go in geography capitals
  // { "facile": "/geography/capitals/easy", "moyen": "/geography/capitals/medium" } are valid commands
  const pathName = window.location.pathname
  const subPathsKeysList = Object.keys(extendedCommandPathMap[lngCode])
  if (subPathsKeysList.includes(pathName)) {
    commandpathMap = { ...commandpathMap, ...extendedCommandPathMap[lngCode][pathName] }
  }

  // ...then handle all redirects to /shop /support or /temporarily-unlock.
  // with for example premiumTopics as ['capitals/medium','capitals/hard'], update to:
  // { facile: "/geography/flags/easy", moyen: "/shop", difficile: "/shop" }
  return updateWithRedirectPaths(user, commandpathMap, temporarilyUnlocked)
}

// For spoken reason, extended navigation accepts different spellings as command
// ex: { drapeau: "/geography/flags", drapeaux: "/geography/flags" }
const createExtendedNavigationCommands = ({
  user = {},
  navigationCommands = ['menu'],
  lngCode = 'en',
  temporarilyUnlocked = false
}) => {
  const commandpathMap = createMainNavigationCommands({
    user,
    navigationCommands,
    lngCode,
    temporarilyUnlocked
  })
  return addAcceptedSpellingCommands(lngCode, commandpathMap)
}

export { createMainNavigationCommands, createExtendedNavigationCommands }
