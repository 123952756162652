import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'
import { browserName } from 'react-device-detect'
// import Snowfall from 'react-snowfall'
import {
  isAnnyangSupported,
  startAnnyangRecording,
  stopAnnyangRecording
} from './lib/speechToTextUtils/annyangSpeechToText'
import readOutLoud from './lib/readOutLoud'
// import { isChristmasTheme } from './lib/themeUtil'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  capitalizeWord
} from './lib/methodsUtil'
import { isDevEnvironment, isReadOutLoudDisabled } from './lib/_processUtil'
import {
  supportedLngCodes,
  findSpokenFromList,
  translateFrom,
  common,
  lngCodeToLanguageMap,
  lngMap,
  ownLanguageIntro,
  updateLanguageWith,
  supportedLanguagesNames,
  makeWhereIAmText
} from './lib/languagesUtils/languageUtil'
import {
  changePathAction,
  updateIsAnnyangSupportedAction,
  updateIsUsingMicrophoneAction,
  updateIsChromeAction,
  updateIsRoboticVoiceNoticeVisibleAction
} from './redux/actions/actions'
import { mapMinimalStateToProps, passDispatchToProps } from './redux/mapToPropsUtil'
import SEO from './components/SEO'
import SpeechButton from './components/SpeechButton'
import ImageChoiceButton from './components/ImageChoiceButton'
import ParentsInfoButton from './components/ParentsInfoButton'
import ReadOutLoudRoboticVoiceNotice from './components/ReadOutLoudRoboticVoiceNotice'
import StyledI18n from './components/StyledI18n'
// import Footer from './components/Footer'
import AppListeningSpeakingVisual from './components/AppListeningSpeakingVisual'
import { wasPathChanged } from './router/routerUtil'
import { createExtendedNavigationCommands } from './lib/speechToTextUtils/speechToTextMethodsUtil'

const translate = translateFrom('home_language_choice')

const updatelanguageWithAndRedirectToHome = (language, dispatch) => {
  updateLanguageWith(language, dispatch)
  dispatch(changePathAction('/home'))
}

class LanguageChoice extends Component {
  constructor(props) {
    super(props)
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    // All this logic is to override isReadyForRelease
    this.state = {
      isReadyForRelease: isDevEnvironment,
      pass: '',
      wasHelpOrWhereAmITriggeredAtLeastOnce: false
    }
  }

  handleKeyUp(e) {
    const { pass } = this.state
    const newWord = pass + e.key
    this.setState({ pass: newWord })
    if (newWord === 'qqq') {
      this.setState({ isReadyForRelease: true })
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    const isSupported = isAnnyangSupported()
    dispatch(updateIsAnnyangSupportedAction(isSupported))
    dispatch(updateIsUsingMicrophoneAction(isSupported))
    dispatch(updateIsChromeAction(browserName === 'Chrome'))
    dispatch(updateIsRoboticVoiceNoticeVisibleAction(browserName !== 'Chrome'))

    // browserDetectedLanguage comes back as 'en-GB', 'en-US', 'en', 'fr' but we only use the 2 first digits to set-up
    const browserDetectedLanguage = i18n.language
    let browserDetectedLngCode = 'en'
    if (browserDetectedLanguage) {
      browserDetectedLngCode = browserDetectedLanguage.slice(0, 2)
    }
    updateLanguageWith(browserDetectedLanguage, dispatch)

    startAnnyangRecording({ dispatch, lngCode: browserDetectedLngCode })
    window.addEventListener('keyup', this.handleKeyUp)
  }

  componentDidUpdate(prevProps) {
    const { transcript: prevTranscript } = prevProps
    const { transcript, dispatch, lngCode } = this.props

    if (prevTranscript !== transcript) {
      const foundLanguageName = findSpokenFromList(lngCode, transcript, supportedLanguagesNames)

      if (foundLanguageName) {
        const foundLanguage = lngMap[lngCode][capitalizeWord(foundLanguageName)]
        updatelanguageWithAndRedirectToHome(foundLanguage, dispatch)
      }

      const navigationCommands = createExtendedNavigationCommands({
        navigationCommands: ['login'],
        lngCode
      })
      const foundCommand = Object.keys(navigationCommands).find(navCommand =>
        transcript.map(ts => ts.toLowerCase()).includes(navCommand)
      )
      if (foundCommand) {
        dispatch(changePathAction(navigationCommands[foundCommand]))
      }
    }
  }

  handleLanguageChange(language) {
    const { dispatch } = this.props
    updatelanguageWithAndRedirectToHome(language, dispatch)
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    stopAnnyangRecording({ dispatch })
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  render() {
    const {
      isAnnyangSupported,
      isUsingMicrophone,
      path,
      transcript,
      language,
      lngCode,
      gender,
      soundLevel,
      dispatch,
      user,
      isChrome,
      isRoboticVoiceNoticeVisible
    } = this.props
    const { isReadyForRelease, wasHelpOrWhereAmITriggeredAtLeastOnce } = this.state

    const detectedLanguage = translate('detected_language')
    const isAnnyangSupportedMessage = translate(
      isAnnyangSupported ? 'language_question' : 'not_supported'
    )

    const text = `${detectedLanguage}. ${isAnnyangSupportedMessage}`

    const callBack = () => {
      if (!isChrome && isUsingMicrophone && isRoboticVoiceNoticeVisible) {
        this.setState({ wasHelpOrWhereAmITriggeredAtLeastOnce: true })
      }
    }

    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)

    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? text : makeWhereIAmText[lngCode]({ title: common('language_choice') }),
        dispatch,
        language,
        gender,
        user,
        callBack
      })
    }

    return isReadyForRelease ? (
      <div className="language-choice-wrapper">
        <SEO />

        {/* {isChristmasTheme && <Snowfall color="white" snowflakeCount={200} />} */}

        {!isNavigationSoundOff && (
          <SpeechButton
            className="language-choice-speech-button"
            text={text}
            readOutLoudCallBack={callBack}
          />
        )}

        <AppListeningSpeakingVisual />

        {wasHelpOrWhereAmITriggeredAtLeastOnce &&
          !isChrome &&
          isUsingMicrophone &&
          isRoboticVoiceNoticeVisible && (
            <ReadOutLoudRoboticVoiceNotice dispatch={dispatch} common={common} />
          )}

        <div className="navigation-box" style={createBorderRadius(0.25)}>
          <div className="detected-language">{detectedLanguage}</div>
          <div className="annyang-supported-state">
            {isAnnyangSupported ? (
              translate('language_question')
            ) : (
              <StyledI18n text={translate('styled_not_supported')} />
            )}
          </div>
          {isAnnyangSupported && (
            <div className="image-choice-images-wrapper">
              {supportedLngCodes.map((lng, index) => (
                <ImageChoiceButton
                  key={lng}
                  value={lng}
                  index={index}
                  altName="language"
                  imageText={
                    ownLanguageIntro[isAnnyangSupported ? 'supported' : 'notSupported'][lng]
                  }
                  onClick={() => this.handleLanguageChange(lngCodeToLanguageMap[lng])}
                  srcUrl={`images/geography/flags/${lng === 'en' ? 'gb' : lng}.svg`}
                  isFirstPage={true}
                />
              ))}
            </div>
          )}
          {browserName === 'Chrome' && (
            <div className="language-choice-general-instructions">
              <StyledI18n text={translate('general_instructions')} />
            </div>
          )}
        </div>

        <div>
          <button
            style={{ zIndex: '999' }}
            className="fake-button-three"
            onClick={() => dispatch(changePathAction('/log-boubou'))}>
            Log Boubou
          </button>

          <button
            style={{ zIndex: '999' }}
            className="fake-button-three"
            onClick={() => dispatch(changePathAction('/admin-panel'))}>
            Boubou Admin panel
          </button>
        </div>

        <div>
          <button
            style={{ zIndex: '999' }}
            className="fake-button-two"
            onClick={() => dispatch(changePathAction('/log-in'))}>
            {common('log_in')}
          </button>

          <button
            style={{ zIndex: '999' }}
            className="fake-button-two"
            onClick={() => dispatch(changePathAction('/sign-up'))}>
            {common('sign_up')}
          </button>

          {isDevEnvironment && (
            <button
              style={{ zIndex: '999' }}
              className="fake-button-two"
              onClick={() => dispatch(changePathAction('/social-sign-up'))}>
              SOCIAL SIGN UPS
            </button>
          )}

          <button
            style={{ zIndex: '999' }}
            className="fake-button-two"
            onClick={() => dispatch(changePathAction('/forgot-password'))}>
            {common('forgot_password')}
          </button>

          <button
            style={{ zIndex: '999' }}
            className="fake-button-two"
            onClick={() => dispatch(changePathAction('/profile'))}>
            {common('profile')}
          </button>

          <button
            style={{ zIndex: '999' }}
            className="fake-button-two"
            onClick={() => dispatch(changePathAction('/achievements'))}>
            {common('achievements')}
          </button>
        </div>

        <ParentsInfoButton />

        {wasPathChanged(path, '/') && <Navigate to={path} replace />}

        {/* <Footer /> */}
      </div>
    ) : (
      <div className="coming-soon" />
    )
  }
}

export default withTranslation()(
  connect(mapMinimalStateToProps, passDispatchToProps)(LanguageChoice)
)
