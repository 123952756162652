import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnAdditionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['beginner', 'easy', 'medium', 'additions', 'mathematics']}
    pageName="learn_additions_page"
    topicNavigationPath="mathematics/additions/learn"
    borderRadius={0.36}
    canonicalPath="/mathematics"
  />
)

export default LearnAdditionsPage
