import React, { useState } from 'react'
import { baseUrl } from '../lib/_processUtil'
import { updateIsRoboticVoiceNoticeVisibleAction } from '../redux/actions/actions'
import ImageWithDimensions from './ImageWithDimensions'

const ReadOutLoudRoboticVoiceNotice = ({ dispatch, common }) => {
  const [isVisible, setIsVisible] = useState(true)

  return (
    isVisible && (
      <div className="read-out-loud-robotic-voice-notice">
        {common('read_out_loud_robotic_voice_notice')}
        <ImageWithDimensions
          alt="close icon"
          className="read-out-loud-robotic-voice-notice-cross"
          src={`${baseUrl}/images/icons/close.webp`}
          onClick={() => {
            dispatch(updateIsRoboticVoiceNoticeVisibleAction(false))
            setIsVisible(false)
          }}
        />
      </div>
    )
  )
}

export default ReadOutLoudRoboticVoiceNotice
