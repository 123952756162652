import React from 'react'
import { baseUrl } from '../lib/_processUtil'
import TopicNavigationPage from '../components/TopicNavigationPage'
import ImageWithDimensions from '../components/ImageWithDimensions'
import { FadeIn, Pulse, BounceIn } from '../lib/animationsUtil'

const ContactErrorPage = () => (
  <TopicNavigationPage
    pageName="contact_error_page"
    borderRadius={0.37}
    SEODescription="E=MC² - Error Message Check - Failed on questions about Speech recognition, fun, games and play on Words, Maths, Geography, Reading, Astronomy, History...">
    {() => (
      <BounceIn className="contact-error-image-wrapper">
        <FadeIn className="contact-error-image-wrapper-fade-in">
          <Pulse delay={1} duration={3}>
            <ImageWithDimensions
              alt="Contact Error Image"
              className="mini-einstein-menu-picture"
              src={`${baseUrl}/images/contact-error.webp`}
            />
          </Pulse>
        </FadeIn>
      </BounceIn>
    )}
  </TopicNavigationPage>
)

export default ContactErrorPage
