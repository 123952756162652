import React from 'react'

const FreeVersionBanner = ({ common }) => (
  <div className="free-version-notice">
    <div className="free-version-notice-title">{common('free-version-notice-title')}</div>
    <div className="free-version-notice-message">{common('free-version-notice-message')}</div>
  </div>
)

export default FreeVersionBanner
