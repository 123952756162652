import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../../components/QuestionsAnswersGame'
import { passDispatchToProps, geographyMapStateToProps } from '../../../../redux/mapToPropsUtil'
import { questionAnswerMap, reverseCommonProps, reverseGameTypeProps } from './util'
import { invertedAnswerQuestionMap } from '../answerQuestionMap'
import { getMultipleChoices, getTranslatedAnswer } from '../../../../lib/methodsUtil'
import { ofCountryModifier } from '../../../../lib/languagesUtils/languageUtil'

const EasyReverseCapitalsPage = props => {
  const { lngCode = 'en', actual: enCountry, actualMultipleChoices } = props

  const country =
    lngCode === 'en'
      ? enCountry
      : getTranslatedAnswer(lngCode, invertedAnswerQuestionMap, enCountry)

  return (
    <QuestionsAnswersGame
      {...props}
      {...reverseCommonProps}
      {...reverseGameTypeProps({
        lngCode,
        questionType: 'capitals',
        questionLevel: 'easy_reverse',
        capital: questionAnswerMap(lngCode)[country],
        country,
        enCountry,
        multipleChoices: getMultipleChoices(
          enCountry,
          actualMultipleChoices,
          2,
          lngCode,
          invertedAnswerQuestionMap,
          ofCountryModifier
        )
      })}
    />
  )
}

export default connect(geographyMapStateToProps, passDispatchToProps)(EasyReverseCapitalsPage)
