import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const MediumDivisionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['quotient', 'remainder']}
    pageName="medium_level_divisions_page"
    topicNavigationPath="mathematics/divisions/medium"
    borderRadius={0.12}
    canonicalPath="/mathematics"
  />
)

export default MediumDivisionsPage
