import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const HardDivisionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['quotient', 'remainder']}
    pageName="hard_level_divisions_page"
    topicNavigationPath="mathematics/divisions/hard"
    borderRadius={0.07}
    canonicalPath="/mathematics"
  />
)

export default HardDivisionsPage
