import React from 'react'
import { baseUrl } from '../lib/_processUtil'
import TopicNavigationPage from '../components/TopicNavigationPage'
import ImageWithDimensions from '../components/ImageWithDimensions'
import { FadeIn, Swing, Wobble, SlideInLeft } from '../lib/animationsUtil'

const ContactSuccessPage = () => (
  <TopicNavigationPage
    pageName="contact_success_page"
    borderRadius={0.97}
    SEODescription="E=MC² - Electronic Message Sent - Ask your questions about Speech recognition, fun, games and play on Words, Maths, Geography, Reading, Astronomy, History...">
    {() => (
      <SlideInLeft className="contact-success-image-wrapper">
        <FadeIn>
          <Wobble delay={1} duration={20}>
            <Swing delay={1} duration={20}>
              <ImageWithDimensions
                alt="Contact Success Image"
                className="mini-einstein-menu-picture"
                src={`${baseUrl}/images/contact-success.webp`}
              />
            </Swing>
          </Wobble>
        </FadeIn>
      </SlideInLeft>
    )}
  </TopicNavigationPage>
)

export default ContactSuccessPage
