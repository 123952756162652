import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, geographyMapStateToProps } from '../../../redux/mapToPropsUtil'
import { commonProps, gameTypeProps } from './util'
import { answerQuestionMap } from './answerQuestionMap'
import { getTranslatedAnswer, getMultipleChoices } from '../../../lib/methodsUtil'

const MediumCapitalsPage = props => {
  const { lngCode = 'en', actual: enCapital, actualMultipleChoices } = props

  const capital =
    lngCode === 'en' ? enCapital : getTranslatedAnswer(lngCode, answerQuestionMap, enCapital)

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'capitals',
        questionLevel: 'medium',
        capital,
        country: answerQuestionMap[lngCode][capital],
        enCountry: answerQuestionMap.en[enCapital],
        multipleChoices: getMultipleChoices(
          enCapital,
          actualMultipleChoices,
          4,
          lngCode,
          answerQuestionMap
        )
      })}
    />
  )
}

export default connect(geographyMapStateToProps, passDispatchToProps)(MediumCapitalsPage)
