import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

// LATER - small update needed - Masculin
// Apprendre les drapeaux // Lesquelles est-ce que tu veux apprendre? Pour les plus connues, dis Les plus connues

const LearnFlagsPage = () => (
  <TopicNavigationPage
    navigationCommands={['famous_ones', 'others', 'flags', 'geography']}
    pageName="learn_flags_page"
    topicNavigationPath="geography/flags/learn"
    borderRadius={0.44}
    canonicalPath="/geography"
  />
)

export default LearnFlagsPage
