import React, { useEffect } from 'react'
import { baseUrl } from '../lib/_processUtil'
import {
  startGoogleAPIRecording,
  stopGoogleAPIRecording
} from '../lib/speechToTextUtils/googleCloudSpeechToText'

const GCloudSpeechToTextTestButtons = ({
  user,
  isListening,
  dispatch,
  lngCode,
  navigationCommands
}) => {
  useEffect(() => {
    const faviconElement = document.querySelector("link[rel*='icon']")

    const changeFaviconTo = iconName => {
      faviconElement.href = `${baseUrl}/images/icons/${iconName}.webp`
    }

    changeFaviconTo('basketball')
    return () => {
      changeFaviconTo('menu')
    }
  }, [])

  return (
    <div>
      <button
        onClick={() =>
          !isListening
            ? startGoogleAPIRecording({
                user,
                dispatch,
                navigationCommands,
                lngCode
              })
            : {}
        }
        disabled={isListening}
        style={{
          background: isListening ? 'red' : 'green',
          color: 'white',
          fontSize: '24px',
          padding: '10px 20px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          marginBottom: '20px',
          boxShadow: '0 3px 5px rgba(0,0,0,0.3)'
        }}>
        {isListening ? 'RECORDING' : '💰 START 💰'}
      </button>
      <button
        onClick={() =>
          isListening ? stopGoogleAPIRecording({ dispatch, forceStopRecording: true }) : {}
        }
        disabled={!isListening}
        style={{
          background: !isListening ? 'grey' : 'crimson',
          color: 'white',
          fontSize: '24px',
          padding: '10px 20px',
          marginLeft: '20px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          marginBottom: '20px',
          boxShadow: '0 3px 5px rgba(0,0,0,0.3)'
        }}>
        {isListening ? 'STOP' : 'DISABLED'}
      </button>
    </div>
  )
}

export default GCloudSpeechToTextTestButtons
