import React, { Component } from 'react'
import { connect } from 'react-redux'
import TopicNavigationPage from '../components/TopicNavigationPage'
import { startRecording, stopRecording } from '../lib/speechToTextUtils/speechToText'
import googleCloudSpeechToText from '../lib/speechToTextUtils/googleCloudSpeechToText'
import { createAndList, capitalizeWord } from '../lib/methodsUtil'
import {
  lngMap,
  languageToLngCodeMap,
  findSpokenFromList,
  lngCodeToLanguageMap,
  supportedLanguagesNames,
  supportedLngCodes,
  updateLanguageWith,
  common
} from '../lib/languagesUtils/languageUtil'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import ImageChoiceButton from '../components/ImageChoiceButton'

const navigationCommands = ['settings']

const updateAnnyangLanguageAndRestart = (dispatch, user, language) => {
  stopRecording(user)({ dispatch })
  updateLanguageWith(language, dispatch)
  startRecording(user)({
    user,
    dispatch,
    navigationCommands,
    lngCode: languageToLngCodeMap[language]
  })
}

class LanguagePage extends Component {
  componentDidMount() {
    this.handleLanguageFlagClick = this.handleLanguageFlagClick.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { transcript, language, lngCode, dispatch, user } = this.props

    if (prevProps.transcript !== transcript) {
      const foundLanguageName = findSpokenFromList(lngCode, transcript, supportedLanguagesNames)

      if (foundLanguageName) {
        const foundLanguage = lngMap[lngCode][capitalizeWord(foundLanguageName)]
        if (foundLanguage !== language) {
          updateAnnyangLanguageAndRestart(dispatch, user, foundLanguage)
        }
      }
    }
  }

  handleLanguageFlagClick(language) {
    const { dispatch, user } = this.props
    updateAnnyangLanguageAndRestart(dispatch, user, language)
  }

  render() {
    const { lngCode, user, dispatch } = this.props

    return (
      <TopicNavigationPage
        navigationCommands={navigationCommands}
        pageName="language_page"
        extraTopicsInstructions={['language_choice']}
        extraIntro={createAndList(supportedLngCodes, 'languages')}
        contentCommands={supportedLngCodes
          .filter(lng => lng !== lngCode)
          .map(lng => common(`languages.${lng}`))}
        borderRadius={0.36}
        SEODescription="E=MC² - Extend Main Communication² - Via Speech Commands, Children and Kids can update the Games to English or French and Play, Learn a New Language. 100% free">
        {() => (
          <div className="image-choice-images-wrapper">
            {supportedLngCodes.map((lng, index) => (
              <ImageChoiceButton
                key={lng}
                value={lng}
                index={index}
                altName="language"
                isSelected={lng === lngCode}
                imageText={`${common(`languages.${lng}`)}${lng === lngCode ? ' ✓' : ''}`}
                onClick={async () => {
                  if (lng !== lngCode) {
                    const language = lngCodeToLanguageMap[lng]
                    this.handleLanguageFlagClick(language)
                    if (user.plan === 'paidPlan') {
                      try {
                        await googleCloudSpeechToText.initializeGoogleApiState({
                          language,
                          dispatch,
                          isLanguageChange: true
                        })
                      } catch (error) {
                        console.error('Error on initializeGoogleApiState:', error)
                      }
                    }
                  }
                }}
                srcUrl={`images/geography/flags/${lng === 'en' ? 'gb' : lng}.svg`}
              />
            ))}
          </div>
        )}
      </TopicNavigationPage>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(LanguagePage)
