import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, wordsMapStateToProps } from '../../../redux/mapToPropsUtil'
import { answerQuestionMap, commonProps, gameTypeProps } from './util'
import { getTranslatedAnswer } from '../../../lib/methodsUtil'

const PlayFoodPage = props => {
  const { lngCode = 'en', actual: enFood } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'play_food',
        food: lngCode === 'en' ? enFood : getTranslatedAnswer(lngCode, answerQuestionMap, enFood)
      })}
    />
  )
}

export default connect(wordsMapStateToProps, passDispatchToProps)(PlayFoodPage)
