import React from 'react'
import LearnPage from '../../../components/LearnPage'
import { getAnimalImageUrl } from '../../../pages/words/animals/util'

const LearnAnimalsPage = () => (
  <LearnPage
    parentPage="animals"
    grandParentPage="words"
    pageName="learn_animals"
    getImageUrl={getAnimalImageUrl}
  />
)

export default LearnAnimalsPage
