import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import '../styles/scss/App.scss'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { common } from '../lib/languagesUtils/languageUtil'
import { wasPathChanged } from '../router/routerUtil'
import { changePathAction, updateIsListeningAction } from '../redux/actions/actions'

class LoginPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(updateIsListeningAction(false))
  }

  render() {
    const { dispatch, path } = this.props
    return (
      <div className="speech-page top-navigation">
        <div>Login Page</div>
        <div>Vocal navigatiom is disabled in this page</div>
        <button className="fake-button-two" onClick={() => dispatch(changePathAction('/menu'))}>
          {common('menu')}
        </button>

        {wasPathChanged(path) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(LoginPage))
