import React from 'react'
import LearnPage from '../../../components/LearnPage'
import { getFoodImageUrl } from '../../../pages/words/food/util'

const LearnFoodPage = () => (
  <LearnPage
    parentPage="food"
    grandParentPage="words"
    pageName="learn_food"
    getImageUrl={getFoodImageUrl}
  />
)

export default LearnFoodPage
