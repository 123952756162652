import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, wordsMapStateToProps } from '../../../redux/mapToPropsUtil'
import { answerQuestionMap, commonProps, gameTypeProps } from './util'
import { getTranslatedAnswer } from '../../../lib/methodsUtil'

const PlayColorsPage = props => {
  const { lngCode = 'en', actual: enColor } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'play_colors',
        color: lngCode === 'en' ? enColor : getTranslatedAnswer(lngCode, answerQuestionMap, enColor)
      })}
    />
  )
}

export default connect(wordsMapStateToProps, passDispatchToProps)(PlayColorsPage)
