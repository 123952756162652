import { makeLanguageAnswers } from '../../../lib/methodsUtil'
import {
  englishCountryNamesAlsoAcceptedAsAnswers,
  frenchCountryNamesAlsoAcceptedAsAnswers
} from '../../../lib/topicsUtils/geographyUtil'

const answerQuestionMap = {
  en: {
    Afghanistan: 'Afghanistan',
    Albania: 'Albania',
    Algeria: 'Algeria',
    Andorra: 'Andorra',
    Angola: 'Angola',
    'Antigua and Barbuda': 'Antigua and Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    Bosnia: 'Bosnia',
    Botswana: 'Botswana',
    Brazil: 'Brazil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgaria',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    Canada: 'Canada',
    'Cape Verde': 'Cape Verde',
    'Central African Republic': 'Central African Republic',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    Comoros: 'Comoros',
    'Costa Rica': 'Costa Rica',
    Croatia: 'Croatia',
    Cuba: 'Cuba',
    Cyprus: 'Cyprus',
    'Czech Republic': 'Czech Republic',
    'Democratic Republic of the Congo': 'Democratic Republic of the Congo',
    Denmark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    'Dominican Republic': 'Dominican Republic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Ethiopia: 'Ethiopia',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Greece: 'Greece',
    Grenada: 'Grenada',
    Guatemala: 'Guatemala',
    Guinea: 'Guinea',
    'Guinea-Bissau': 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    Honduras: 'Honduras',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    Iran: 'Iran',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    Israel: 'Israel',
    Italy: 'Italy',
    'Ivory Coast': 'Ivory Coast',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kosovo: 'Kosovo',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    Libya: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Macedonia: 'Macedonia',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    'Marshall Islands': 'Marshall Islands',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mexico: 'Mexico',
    Micronesia: 'Micronesia',
    Moldova: 'Moldova',
    Monaco: 'Monaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    'New Zealand': 'New Zealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    'North Korea': 'North Korea',
    Norway: 'Norway',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    Panama: 'Panama',
    'Papua New Guinea': 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Poland: 'Poland',
    Portugal: 'Portugal',
    Qatar: 'Qatar',
    'Republic of the Congo': 'Republic of the Congo',
    Romania: 'Romania',
    Russia: 'Russia',
    Rwanda: 'Rwanda',
    'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
    'Saint Lucia': 'Saint Lucia',
    'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'San Marino',
    'São Tomé and Principe': 'São Tomé and Principe',
    'Saudi Arabia': 'Saudi Arabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapore',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    'Solomon Islands': 'Solomon Islands',
    Somalia: 'Somalia',
    'South Africa': 'South Africa',
    'South Korea': 'South Korea',
    'South Sudan': 'South Sudan',
    Spain: 'Spain',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    Swaziland: 'Swaziland',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Syria: 'Syria',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajikistan',
    Tanzania: 'Tanzania',
    Thailand: 'Thailand',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinidad and Tobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Turkmenistan: 'Turkmenistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    'United Arab Emirates': 'United Arab Emirates',
    'United Kingdom': 'United Kingdom',
    'United States': 'United States',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    Vatican: 'Vatican',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe'
  },

  fr: {
    Afghanistan: 'Afghanistan',
    Albanie: 'Albania',
    Algérie: 'Algeria',
    Andorre: 'Andorra',
    Angola: 'Angola',
    'Antigua-et-Barbuda': 'Antigua and Barbuda',
    Argentine: 'Argentina',
    Arménie: 'Armenia',
    Australie: 'Australia',
    Autriche: 'Austria',
    Azerbaïdjan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahreïn: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbade: 'Barbados',
    Biélorussie: 'Belarus',
    Belgique: 'Belgium',
    Bélize: 'Belize',
    Bénin: 'Benin',
    Bhoutan: 'Bhutan',
    Bolivie: 'Bolivia',
    'Bosnie-Herzégovine': 'Bosnia',
    Botswana: 'Botswana',
    Brésil: 'Brazil',
    Brunei: 'Brunei',
    Bulgarie: 'Bulgaria',
    Burkina: 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodge: 'Cambodia',
    Cameroun: 'Cameroon',
    Canada: 'Canada',
    'Cap-Vert': 'Cape Verde',
    Centrafrique: 'Central African Republic',
    Tchad: 'Chad',
    Chili: 'Chile',
    Chine: 'China',
    Colombie: 'Colombia',
    Comores: 'Comoros',
    'Costa Rica': 'Costa Rica',
    Croatie: 'Croatia',
    Cuba: 'Cuba',
    Chypre: 'Cyprus',
    'République Tchèque': 'Czech Republic',
    'République démocratique du Congo': 'Democratic Republic of the Congo',
    Danemark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominique: 'Dominica',
    'République Dominicaine': 'Dominican Republic',
    Équateur: 'Ecuador',
    Égypte: 'Egypt',
    Salvador: 'El Salvador',
    'Guinée équatoriale': 'Equatorial Guinea',
    Érythrée: 'Eritrea',
    Estonie: 'Estonia',
    Éthiopie: 'Ethiopia',
    Fidji: 'Fiji',
    Finlande: 'Finland',
    France: 'France',
    Gabon: 'Gabon',
    Gambie: 'Gambia',
    Géorgie: 'Georgia',
    Allemagne: 'Germany',
    Ghana: 'Ghana',
    Grèce: 'Greece',
    Grenade: 'Grenada',
    Guatémala: 'Guatemala',
    Guinée: 'Guinea',
    'Guinée-Bissao': 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haïti: 'Haiti',
    Honduras: 'Honduras',
    Hongrie: 'Hungary',
    Islande: 'Iceland',
    Inde: 'India',
    Indonésie: 'Indonesia',
    Iran: 'Iran',
    Irak: 'Iraq',
    Irlande: 'Ireland',
    Israël: 'Israel',
    Italie: 'Italy',
    "Côte d'Ivoire": 'Ivory Coast',
    Jamaïque: 'Jamaica',
    Japon: 'Japan',
    Jordanie: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kénya: 'Kenya',
    Kiribati: 'Kiribati',
    Kosovo: 'Kosovo',
    Koweït: 'Kuwait',
    Kirghizstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Lettonie: 'Latvia',
    Liban: 'Lebanon',
    Lésotho: 'Lesotho',
    Libéria: 'Liberia',
    Libye: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lituanie: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Macédoine: 'Macedonia',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaisie: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malte: 'Malta',
    'Îles Marshall': 'Marshall Islands',
    Mauritanie: 'Mauritania',
    Maurice: 'Mauritius',
    Mexique: 'Mexico',
    Micronésie: 'Micronesia',
    Moldavie: 'Moldova',
    Monaco: 'Monaco',
    Mongolie: 'Mongolia',
    Monténégro: 'Montenegro',
    Maroc: 'Morocco',
    Mozambique: 'Mozambique',
    Birmanie: 'Myanmar',
    Namibie: 'Namibia',
    Nauru: 'Nauru',
    Népal: 'Nepal',
    'Pays-Bas': 'Netherlands',
    'Nouvelle-Zélande': 'New Zealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigéria: 'Nigeria',
    'Corée du Nord': 'North Korea',
    Norvège: 'Norway',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palaos: 'Palau',
    Panama: 'Panama',
    'Papouasie-Nouvelle-Guinée': 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Pérou: 'Peru',
    Philippines: 'Philippines',
    Pologne: 'Poland',
    Portugal: 'Portugal',
    Qatar: 'Qatar',
    Congo: 'Republic of the Congo',
    Roumanie: 'Romania',
    Russie: 'Russia',
    Rwanda: 'Rwanda',
    'Saint-Christophe-et-Niévès': 'Saint Kitts and Nevis',
    'Sainte-Lucie': 'Saint Lucia',
    'Saint-Vincent-et-les-Grenadines': 'Saint Vincent and the Grenadines',
    Samoa: 'Samoa',
    'Saint-Marin': 'San Marino',
    'Sao Tomé-et-Principe': 'São Tomé and Principe',
    'Arabie Saoudite': 'Saudi Arabia',
    Sénégal: 'Senegal',
    Serbie: 'Serbia',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapour: 'Singapore',
    Slovaquie: 'Slovakia',
    Slovénie: 'Slovenia',
    Salomon: 'Solomon Islands',
    Somalie: 'Somalia',
    'Afrique du Sud': 'South Africa',
    'Corée du Sud': 'South Korea',
    'Soudan du Sud': 'South Sudan',
    Espagne: 'Spain',
    'Sri Lanka': 'Sri Lanka',
    Soudan: 'Sudan',
    Suriname: 'Suriname',
    Eswatini: 'Swaziland',
    Suède: 'Sweden',
    Suisse: 'Switzerland',
    Syrie: 'Syria',
    Taïwan: 'Taiwan',
    Tadjikistan: 'Tajikistan',
    Tanzanie: 'Tanzania',
    Thaïlande: 'Thailand',
    'Timor oriental': 'Timor-Leste',
    Togo: 'Togo',
    Tonga: 'Tonga',
    'Trinité-et-Tobago': 'Trinidad and Tobago',
    Tunisie: 'Tunisia',
    Turquie: 'Turkey',
    Turkménistan: 'Turkmenistan',
    Tuvalu: 'Tuvalu',
    Ouganda: 'Uganda',
    Ukraine: 'Ukraine',
    'Émirats Arabes Unis': 'United Arab Emirates',
    'Royaume-Uni': 'United Kingdom',
    'États-Unis': 'United States',
    Uruguay: 'Uruguay',
    Ouzbékistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    Vatican: 'Vatican',
    Vénézuéla: 'Venezuela',
    Vietnam: 'Vietnam',
    Yémen: 'Yemen',
    Zambie: 'Zambia',
    Zimbabwé: 'Zimbabwe'
  }
}

const alsoAcceptedAsAnswersMap = {
  en: englishCountryNamesAlsoAcceptedAsAnswers,
  fr: {
    ...makeLanguageAnswers('fr', answerQuestionMap),
    ...frenchCountryNamesAlsoAcceptedAsAnswers
  }
}

export { answerQuestionMap, alsoAcceptedAsAnswersMap }
