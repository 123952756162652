import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import '../styles/scss/App.scss'
import SEO from '../components/SEO'
import PageHeader from '../components/PageHeader'
import NavigationButtons from '../components/NavigationButtons'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import FreeVersionBanner from '../components/FreeVersionBanner'
import {
  translateFrom,
  common,
  instructionTranslate,
  makeWhereIAmText
} from '../lib/languagesUtils/languageUtil'
import {
  startRecording,
  stopRecording,
  isUsingAnnyang
} from '../lib/speechToTextUtils/speechToText'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius,
  isPaidUser,
  isValidEmailFormat
} from '../lib/methodsUtil'
import { isReadOutLoudDisabled } from '../lib/_processUtil'
import readOutLoud from '../lib/readOutLoud'
import { changePathAction } from '../redux/actions/actions'

// SSS - Prefill name and email with user details if logged in + disabled.

const navigationCommands = ['questions', 'about', 'menu']

const translate = translateFrom('contact_page')

const makeOptions = arr =>
  arr.map((option, i) => (
    <option value={option} key={i}>
      {option}
    </option>
  ))

class ContactPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      topic: '',
      message: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
  }

  componentDidMount() {
    const { lngCode, user, dispatch } = this.props
    startRecording(user)({ user, dispatch, navigationCommands, lngCode })
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDropdownChange(e) {
    this.setState({ topic: `Mini Einstein - ${e.target.value}` })
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    event.preventDefault()

    const formData = new FormData(event.target)
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => dispatch(changePathAction('/contact-success')))
      .catch(() => {
        dispatch(changePathAction('/contact-error'))
      })
  }

  handleClearForm() {
    this.setState({
      name: '',
      email: '',
      topic: '',
      message: ''
    })
  }

  render() {
    const { user, path, soundLevel, language, lngCode, transcript, dispatch, isAnnyangSupported } =
      this.props
    const { name, email, topic, message } = this.state

    const disableSubmit = !name || !isValidEmailFormat(email) || topic === '' || !message
    const disableClear = !name && !email && topic === '' && !message
    const optionsList = [1, 2, 3].map(n => translate(`option_${n}`))

    const customNavigationCommands = {}
    navigationCommands.forEach(command => {
      customNavigationCommands[common(command).toLowerCase()] = `/${command.replace(/_/g, '-')}`
    })

    const intro = translate('intro')
    const notice = translate('notice')
    const text = `${intro}. ${notice}`
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? text : makeWhereIAmText[lngCode]({ title: common('contact') }),
        dispatch,
        language,
        clearTranscriptOnEnd: true,
        navigationCommands: customNavigationCommands,
        user
      })
    }

    return (
      <div className="contact-page top-navigation">
        <SEO description="E=MC² - Everyone May Connect²" path={path} />

        <div className="navigation-box form-navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} intro={intro} />

          <div className="contact-page-note">{notice}</div>

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />

          <hr />

          <form className="form" name="contact" onSubmit={this.handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-fields">
              <div className="form-column">
                <div className="form-input-title">{common('name')}:</div>
                <input
                  name="name"
                  type="text"
                  onChange={this.handleInputChange}
                  value={name}
                  style={createBorderRadius(0.1)}
                />

                <div className="form-input-title">{common('email')}:</div>
                <input
                  name="email"
                  type="email"
                  onChange={this.handleInputChange}
                  value={email}
                  style={createBorderRadius(0.4, true)}
                />

                <div className="form-input-title">{common('topic')}:</div>
                <select
                  name="topic"
                  onChange={this.handleDropdownChange}
                  style={createBorderRadius(0.9, true)}
                  // The below removes the value of `Mini Einstein - ` from the dropdown
                  value={topic !== '' ? topic.substring(18) : topic}>
                  <option value="" disabled>
                    {instructionTranslate('select')}
                  </option>
                  {makeOptions(optionsList)}
                </select>
              </div>

              <div className="form-column">
                <div className="form-input-title">{common('message')}:</div>
                <textarea
                  onChange={this.handleInputChange}
                  value={message}
                  name="message"
                  // style={createBorderRadius(0.001)}
                  style={{ borderRadius: '8px 25px / 51px 242px' }}
                />
              </div>
            </div>

            <div className="form-buttons">
              <button
                type="submit"
                disabled={disableSubmit}
                className={disableSubmit ? 'disabled' : ''}
                style={createBorderRadius(0.9, true)}>
                {common('send').toUpperCase()}
              </button>
              <button
                onClick={this.handleClearForm}
                disabled={disableClear}
                className={disableClear ? 'disabled' : ''}
                style={createBorderRadius(0.1)}>
                {common('clear').toUpperCase()}
              </button>
            </div>
          </form>

          {isUsingAnnyang(user) && <FreeVersionBanner common={common} />}

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}

          {path !== '/contact' && <Navigate to={path} replace />}
        </div>
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(ContactPage))
