import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { routeComponentMap, routeList } from './routerUtil'
import { baseUrl } from '../lib/_processUtil'

const AppRouter = () => {
  return (
    <BrowserRouter basename={baseUrl}>
      <Routes>
        {routeList.map(route => (
          <Route
            key={route}
            exact={route !== '*'}
            path={route}
            element={routeComponentMap[route]}
          />
        ))}
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
