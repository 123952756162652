import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const SubtractionsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['beginner', 'easy', 'medium', 'hard', 'learn']}
    pageName="subtractions_page"
    customNavigationPath="/mathematics/subtractions"
  />
)

export default SubtractionsPage
