import actions from './action-types'

export const changePathAction = path => ({
  type: actions.changePath,
  payload: path
})

export const getAWinningImageAction = () => ({
  type: actions.getAWinningImage
})

export const getUserAction = () => ({
  type: actions.getUser
})

export const setUserAction = user => ({
  type: actions.setUser,
  payload: user
})

export const isCorrectAnswerAction = () => ({
  type: actions.isCorrectAnswer
})

export const isPlayingAction = isPlaying => ({
  type: actions.isPlaying,
  payload: isPlaying
})

export const prepareLearningAction = data => ({
  type: actions.prepareLearning,
  payload: data
})

export const randomizeACountryAction = pageName => ({
  type: actions.randomizeACountry,
  payload: pageName
})

export const randomizeAWordAction = pageName => ({
  type: actions.randomizeAWord,
  payload: pageName
})

export const randomizeNumbersAction = pageName => ({
  type: actions.randomizeNumbers,
  payload: pageName
})

export const randomizeReadingItemsAction = pageName => ({
  type: actions.randomizeReadingItems,
  payload: pageName
})

export const resetForNextQuestionAction = () => ({
  type: actions.resetForNextQuestion
})

export const resetGeographyAction = () => ({
  type: actions.resetGeography
})

export const resetLearningAction = () => ({
  type: actions.resetLearning
})

export const resetMathematicsAction = () => ({
  type: actions.resetMathematics
})

export const resetReadingAction = () => ({
  type: actions.resetReading
})

export const resetWordsAction = () => ({
  type: actions.resetWords
})

export const saveTranscriptAction = transcript => ({
  type: actions.saveTranscript,
  payload: transcript
})

export const startLearningAction = data => ({
  type: actions.startLearning,
  payload: data
})

export const updateGenderAction = gender => ({
  type: actions.updateGender,
  payload: gender
})

export const updateIsAnnyangSupportedAction = isAnnyangSupported => ({
  type: actions.updateIsAnnyangSupported,
  payload: isAnnyangSupported
})

export const updateIsUsingMicrophoneAction = isUsingMicrophone => ({
  type: actions.updateIsUsingMicrophone,
  payload: isUsingMicrophone
})

export const updateIsChromeAction = isChrome => ({
  type: actions.updateIsChrome,
  payload: isChrome
})

export const updateIsListeningAction = isListening => ({
  type: actions.updateIsListening,
  payload: isListening
})

export const updateIsRoboticVoiceNoticeVisibleAction = isVisible => ({
  type: actions.updateIsRoboticVoiceNoticeVisible,
  payload: isVisible
})

export const updateLanguageAction = language => ({
  type: actions.updateLanguage,
  payload: language
})

export const updateLearningItemAction = data => ({
  type: actions.updateLearningItem,
  payload: data
})

export const updateSoundLevelAction = soundLevel => ({
  type: actions.updateSoundLevel,
  payload: soundLevel
})

export const updateTemporarilyUnlockedAction = date => ({
  type: actions.updateTemporarilyUnlocked,
  payload: date
})
