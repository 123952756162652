import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'
import { common } from '../../lib/languagesUtils/languageUtil'

const CapitalsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['easy', 'medium', 'hard', 'reverse', 'learn']}
    pageName="capitals_page"
    customNavigationPath="/geography/capitals"
    extraNavigationCommands={{
      [common('reverse').toLowerCase()]: '/geography/capitals/reverse'
    }}
  />
)

export default CapitalsPage
