import { blockGoogleSpeechToTextAPI } from '../_processUtil'
import { isPaidUser } from '../methodsUtil'
import {
  isAnnyangSupported,
  startAnnyangRecording,
  stopAnnyangRecording,
  resumeAnnyangRecording
} from './annyangSpeechToText'
import {
  startGoogleAPIRecording,
  resumeGoogleAPIRecording,
  stopGoogleAPIRecording
} from './googleCloudSpeechToText'

const useGoogleAPI = user => !blockGoogleSpeechToTextAPI && isPaidUser(user)

const isUsingAnnyang = user => isAnnyangSupported && !isPaidUser(user)

// This logic is needed because not all browsers support Annyang, but all support Google Speech API
// so a user with a paid account using a non-Annyang supported browser gets access to Microphone via Google
// as soon as he/she logs in
const isUsingMicrophone = user => isUsingAnnyang(user) || isPaidUser(user)

// Most Modern browsers support speech.googleapis.com/v1/speech
// Free users use Annyang / Paid users use a combination of both: Annyang as first option
// and Google API as back-up (5 seconds non-detect from Annyang)
const startRecording = user =>
  useGoogleAPI(user) ? startGoogleAPIRecording : startAnnyangRecording
const stopRecording = user => (useGoogleAPI(user) ? stopGoogleAPIRecording : stopAnnyangRecording)
const resumeRecording = user =>
  useGoogleAPI(user) ? resumeGoogleAPIRecording : resumeAnnyangRecording // startGoogleAPIRecording ?

export {
  isAnnyangSupported,
  isUsingMicrophone,
  isUsingAnnyang,
  startRecording,
  stopRecording,
  resumeRecording
}
