import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const FlagsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['easy', 'medium', 'hard', 'learn']}
    pageName="flags_page"
    customNavigationPath="/geography/flags"
  />
)

export default FlagsPage
