import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnMultiplicationsPage = () => (
  <TopicNavigationPage
    navigationCommands={['beginner', 'easy', 'medium', 'multiplications', 'mathematics']}
    pageName="learn_multiplications_page"
    topicNavigationPath="mathematics/multiplications/learn"
    borderRadius={0.53}
    canonicalPath="/mathematics"
  />
)

export default LearnMultiplicationsPage
