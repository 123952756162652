import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, geographyMapStateToProps } from '../../../redux/mapToPropsUtil'
import { commonProps, gameTypeProps } from './util'
import { answerQuestionMap } from './answerQuestionMap'
import { getMultipleChoices, getTranslatedAnswer } from '../../../lib/methodsUtil'
import { ofCountryLocationModifier } from '../../../lib/languagesUtils/languageUtil'

const EasyLocationsPage = props => {
  const { lngCode = 'en', actual: enCountry, actualMultipleChoices } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'locations',
        questionLevel: 'easy',
        country:
          lngCode === 'en' ? enCountry : getTranslatedAnswer(lngCode, answerQuestionMap, enCountry),

        multipleChoices: getMultipleChoices(
          enCountry,
          actualMultipleChoices,
          2,
          lngCode,
          answerQuestionMap,
          ofCountryLocationModifier
        ),
        enCountry
      })}
    />
  )
}

export default connect(geographyMapStateToProps, passDispatchToProps)(EasyLocationsPage)
