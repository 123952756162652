import React from 'react'
import { Trans } from 'react-i18next'
import PageHeader from '../components/PageHeader'
import { createBorderRadius } from '../lib/methodsUtil'
import { translateFrom } from '../lib/languagesUtils/languageUtil'

const i18nextTransStyling = {
  italic: <i />,
  bold: <strong />,
  red: <span style={{ color: 'red' }} />,
  blue: <span style={{ color: 'blue' }} />,
  green: <span style={{ color: 'green' }} />
}

const translate = translateFrom('privacy_policy_page')

const PrivacyPolicyPage = () => (
  <div className="privacy-policy-page top-navigation">
    <div className="navigation-box" style={createBorderRadius(0.5, true)}>
      <PageHeader title={translate('title')} intro={translate('intro')} />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
        <div className="policy-section" key={n}>
          <strong>{translate(`sections.${n}.title`)}</strong>
          <Trans defaults={translate(`sections.${n}.text`)} components={i18nextTransStyling} />
        </div>
      ))}
      <hr />
      <div>{translate('outro')}</div>
    </div>
  </div>
)

export default PrivacyPolicyPage
