import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const ColorsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['learn', 'play']}
    pageName="colors_page"
    customNavigationPath="/words/colors"
  />
)

export default ColorsPage
