import { makeLanguageAnswers } from '../../../lib/methodsUtil'
import {
  englishCountryNamesAlsoAcceptedAsAnswers,
  frenchCountryNamesAlsoAcceptedAsAnswers
} from '../../../lib/topicsUtils/geographyUtil'

const answerQuestionMap = {
  en: {
    Afghanistan: 'AF',
    Albania: 'AL',
    Algeria: 'DZ',
    Andorra: 'AD',
    Angola: 'AO',
    'Antigua and Barbuda': 'AG',
    Argentina: 'AR',
    Armenia: 'AM',
    Australia: 'AU',
    Austria: 'AT',
    Azerbaijan: 'AZ',
    Bahamas: 'BS',
    Bahrain: 'BH',
    Bangladesh: 'BD',
    Barbados: 'BB',
    Belarus: 'BY',
    Belgium: 'BE',
    Belize: 'BZ',
    Benin: 'BJ',
    Bhutan: 'BT',
    Bolivia: 'BO',
    Bosnia: 'BA',
    Botswana: 'BW',
    Brazil: 'BR',
    Brunei: 'BN',
    Bulgaria: 'BG',
    'Burkina Faso': 'BF',
    Burundi: 'BI',
    Cambodia: 'KH',
    Cameroon: 'CM',
    Canada: 'CA',
    'Cape Verde': 'CV',
    'Central African Republic': 'CF',
    Chad: 'TD',
    Chile: 'CL',
    China: 'CN',
    Colombia: 'CO',
    Comoros: 'KM',
    'Costa Rica': 'CR',
    Croatia: 'HR',
    Cuba: 'CU',
    Cyprus: 'CY',
    'Czech Republic': 'CZ',
    'Democratic Republic of the Congo': 'CD',
    Denmark: 'DK',
    Djibouti: 'DJ',
    Dominica: 'DM',
    'Dominican Republic': 'DO',
    Ecuador: 'EC',
    Egypt: 'EG',
    'El Salvador': 'SV',
    England: 'GB-ENG',
    'Equatorial Guinea': 'GQ',
    Eritrea: 'ER',
    Estonia: 'EE',
    Ethiopia: 'ET',
    Fiji: 'FJ',
    Finland: 'FI',
    France: 'FR',
    Gabon: 'GA',
    Gambia: 'GM',
    Georgia: 'GE',
    Germany: 'DE',
    Ghana: 'GH',
    Greece: 'GR',
    Grenada: 'GD',
    Guatemala: 'GT',
    Guinea: 'GN',
    'Guinea-Bissau': 'GW',
    Guyana: 'GY',
    Haiti: 'HT',
    Honduras: 'HN',
    Hungary: 'HU',
    Iceland: 'IS',
    India: 'IN',
    Indonesia: 'ID',
    Iran: 'IR',
    Iraq: 'IQ',
    Ireland: 'IE',
    Israel: 'IL',
    Italy: 'IT',
    'Ivory Coast': 'CI',
    Jamaica: 'JM',
    Japan: 'JP',
    Jordan: 'JO',
    Kazakhstan: 'KZ',
    Kenya: 'KE',
    Kiribati: 'KI',
    Kosovo: 'XK',
    Kuwait: 'KW',
    Kyrgyzstan: 'KG',
    Laos: 'LA',
    Latvia: 'LV',
    Lebanon: 'LB',
    Lesotho: 'LS',
    Liberia: 'LR',
    Libya: 'LY',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    Macedonia: 'MK',
    Madagascar: 'MG',
    Malawi: 'MW',
    Malaysia: 'MY',
    Maldives: 'MV',
    Mali: 'ML',
    Malta: 'MT',
    'Marshall Islands': 'MH',
    Mauritania: 'MR',
    Mauritius: 'MU',
    Mexico: 'MX',
    Micronesia: 'FM',
    Moldova: 'MD',
    Monaco: 'MC',
    Mongolia: 'MN',
    Montenegro: 'ME',
    Morocco: 'MA',
    Mozambique: 'MZ',
    Myanmar: 'MM',
    Namibia: 'NA',
    Nauru: 'NR',
    Nepal: 'NP',
    Netherlands: 'NL',
    'New Zealand': 'NZ',
    Nicaragua: 'NI',
    Niger: 'NE',
    Nigeria: 'NG',
    'North Korea': 'KP',
    Norway: 'NO',
    Oman: 'OM',
    Pakistan: 'PK',
    Palau: 'PW',
    Panama: 'PA',
    'Papua New Guinea': 'PG',
    Paraguay: 'PY',
    Peru: 'PE',
    Philippines: 'PH',
    Poland: 'PL',
    Portugal: 'PT',
    Qatar: 'QA',
    'Republic of the Congo': 'CG',
    Romania: 'RO',
    Russia: 'RU',
    Rwanda: 'RW',
    'Saint Kitts and Nevis': 'KN',
    'Saint Lucia': 'LC',
    'Saint Vincent and the Grenadines': 'VC',
    Samoa: 'WS',
    'San Marino': 'SM',
    'São Tomé and Principe': 'ST',
    'Saudi Arabia': 'SA',
    Scotland: 'GB-SCT',
    Senegal: 'SN',
    Serbia: 'RS',
    Seychelles: 'SC',
    'Sierra Leone': 'SL',
    Singapore: 'SG',
    Slovakia: 'SK',
    Slovenia: 'SI',
    'Solomon Islands': 'SB',
    Somalia: 'SO',
    'South Africa': 'ZA',
    'South Korea': 'KR',
    'South Sudan': 'SS',
    Spain: 'ES',
    'Sri Lanka': 'LK',
    Sudan: 'SD',
    Suriname: 'SR',
    Swaziland: 'SZ',
    Sweden: 'SE',
    Switzerland: 'CH',
    Syria: 'SY',
    Taiwan: 'TW',
    Tajikistan: 'TJ',
    Tanzania: 'TZ',
    Thailand: 'TH',
    'Timor-Leste': 'TL',
    Togo: 'TG',
    Tonga: 'TO',
    'Trinidad and Tobago': 'TT',
    Tunisia: 'TN',
    Turkey: 'TR',
    Turkmenistan: 'TM',
    Tuvalu: 'TV',
    Uganda: 'UG',
    Ukraine: 'UA',
    'United Arab Emirates': 'AE',
    'United Kingdom': 'GB',
    'United States': 'US',
    Uruguay: 'UY',
    Uzbekistan: 'UZ',
    Vanuatu: 'VU',
    Vatican: 'VA',
    Venezuela: 'VE',
    Vietnam: 'VN',
    Wales: 'GB-WLS',
    Yemen: 'YE',
    Zambia: 'ZM',
    Zimbabwe: 'ZW'
  },

  fr: {
    Afghanistan: 'AF',
    Albanie: 'AL',
    Algérie: 'DZ',
    Andorre: 'AD',
    Angola: 'AO',
    'Antigua-et-Barbuda': 'AG',
    Argentine: 'AR',
    Arménie: 'AM',
    Australie: 'AU',
    Autriche: 'AT',
    Azerbaïdjan: 'AZ',
    Bahamas: 'BS',
    Bahreïn: 'BH',
    Bangladesh: 'BD',
    Barbade: 'BB',
    Biélorussie: 'BY',
    Belgique: 'BE',
    Bélize: 'BZ',
    Bénin: 'BJ',
    Bhoutan: 'BT',
    Bolivie: 'BO',
    'Bosnie-Herzégovine': 'BA',
    Botswana: 'BW',
    Brésil: 'BR',
    Brunei: 'BN',
    Bulgarie: 'BG',
    Burkina: 'BF',
    Burundi: 'BI',
    Cambodge: 'KH',
    Cameroun: 'CM',
    Canada: 'CA',
    'Cap-Vert': 'CV',
    Centrafrique: 'CF',
    Tchad: 'TD',
    Chili: 'CL',
    Chine: 'CN',
    Colombie: 'CO',
    Comores: 'KM',
    'Costa Rica': 'CR',
    Croatie: 'HR',
    Cuba: 'CU',
    Chypre: 'CY',
    'République Tchèque': 'CZ',
    'République démocratique du Congo': 'CD',
    Danemark: 'DK',
    Djibouti: 'DJ',
    Dominique: 'DM',
    'République Dominicaine': 'DO',
    Équateur: 'EC',
    Égypte: 'EG',
    Salvador: 'SV',
    Angleterre: 'GB-ENG',
    'Guinée équatoriale': 'GQ',
    Érythrée: 'ER',
    Estonie: 'EE',
    Éthiopie: 'ET',
    Fidji: 'FJ',
    Finlande: 'FI',
    France: 'FR',
    Gabon: 'GA',
    Gambie: 'GM',
    Géorgie: 'GE',
    Allemagne: 'DE',
    Ghana: 'GH',
    Grèce: 'GR',
    Grenade: 'GD',
    Guatémala: 'GT',
    Guinée: 'GN',
    'Guinée-Bissao': 'GW',
    Guyana: 'GY',
    Haïti: 'HT',
    Honduras: 'HN',
    Hongrie: 'HU',
    Islande: 'IS',
    Inde: 'IN',
    Indonésie: 'ID',
    Iran: 'IR',
    Irak: 'IQ',
    Irlande: 'IE',
    Israël: 'IL',
    Italie: 'IT',
    "Côte d'Ivoire": 'CI',
    Jamaïque: 'JM',
    Japon: 'JP',
    Jordanie: 'JO',
    Kazakhstan: 'KZ',
    Kénya: 'KE',
    Kiribati: 'KI',
    Kosovo: 'XK',
    Koweït: 'KW',
    Kirghizstan: 'KG',
    Laos: 'LA',
    Lettonie: 'LV',
    Liban: 'LB',
    Lésotho: 'LS',
    Libéria: 'LR',
    Libye: 'LY',
    Liechtenstein: 'LI',
    Lituanie: 'LT',
    Luxembourg: 'LU',
    Macédoine: 'MK',
    Madagascar: 'MG',
    Malawi: 'MW',
    Malaisie: 'MY',
    Maldives: 'MV',
    Mali: 'ML',
    Malte: 'MT',
    'Îles Marshall': 'MH',
    Mauritanie: 'MR',
    Maurice: 'MU',
    Mexique: 'MX',
    Micronésie: 'FM',
    Moldavie: 'MD',
    Monaco: 'MC',
    Mongolie: 'MN',
    Monténégro: 'ME',
    Maroc: 'MA',
    Mozambique: 'MZ',
    Birmanie: 'MM',
    Namibie: 'NA',
    Nauru: 'NR',
    Népal: 'NP',
    'Pays-Bas': 'NL',
    'Nouvelle-Zélande': 'NZ',
    Nicaragua: 'NI',
    Niger: 'NE',
    Nigéria: 'NG',
    'Corée du Nord': 'KP',
    Norvège: 'NO',
    Oman: 'OM',
    Pakistan: 'PK',
    Palaos: 'PW',
    Panama: 'PA',
    'Papouasie-Nouvelle-Guinée': 'PG',
    Paraguay: 'PY',
    Pérou: 'PE',
    Philippines: 'PH',
    Pologne: 'PL',
    Portugal: 'PT',
    Qatar: 'QA',
    Congo: 'CG',
    Roumanie: 'RO',
    Russie: 'RU',
    Rwanda: 'RW',
    'Saint-Christophe-et-Niévès': 'KN',
    'Sainte-Lucie': 'LC',
    'Saint-Vincent-et-les-Grenadines': 'VC',
    Samoa: 'WS',
    'Saint-Marin': 'SM',
    'Sao Tomé-et-Principe': 'ST',
    'Arabie Saoudite': 'SA',
    Ecosse: 'GB-SCT',
    Sénégal: 'SN',
    Serbie: 'RS',
    Seychelles: 'SC',
    'Sierra Leone': 'SL',
    Singapour: 'SG',
    Slovaquie: 'SK',
    Slovénie: 'SI',
    Salomon: 'SB',
    Somalie: 'SO',
    'Afrique du Sud': 'ZA',
    'Corée du Sud': 'KR',
    'Soudan du Sud': 'SS',
    Espagne: 'ES',
    'Sri Lanka': 'LK',
    Soudan: 'SD',
    Suriname: 'SR',
    Eswatini: 'SZ',
    Suède: 'SE',
    Suisse: 'CH',
    Syrie: 'SY',
    Taïwan: 'TW',
    Tadjikistan: 'TJ',
    Tanzanie: 'TZ',
    Thaïlande: 'TH',
    'Timor oriental': 'TL',
    Togo: 'TG',
    Tonga: 'TO',
    'Trinité-et-Tobago': 'TT',
    Tunisie: 'TN',
    Turquie: 'TR',
    Turkménistan: 'TM',
    Tuvalu: 'TV',
    Ouganda: 'UG',
    Ukraine: 'UA',
    'Émirats Arabes Unis': 'AE',
    'Royaume-Uni': 'GB',
    'États-Unis': 'US',
    Uruguay: 'UY',
    Ouzbékistan: 'UZ',
    Vanuatu: 'VU',
    Vatican: 'VA',
    Vénézuéla: 'VE',
    Vietnam: 'VN',
    'Pays De Galles': 'GB-WLS',
    Yémen: 'YE',
    Zambie: 'ZM',
    Zimbabwé: 'ZW'
  }
}

const alsoAcceptedAsAnswersMap = {
  en: englishCountryNamesAlsoAcceptedAsAnswers,
  fr: {
    ...makeLanguageAnswers('fr', answerQuestionMap),
    ...frenchCountryNamesAlsoAcceptedAsAnswers
  }
}

export { answerQuestionMap, alsoAcceptedAsAnswersMap }
