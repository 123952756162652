import { baseUrl } from '../../lib/_processUtil'
import { getSignName } from './answerQuestionMap'
import { randomizeNumbersAction, resetMathematicsAction } from '../../redux/actions/actions'
import { getMathematicsAlsoAcceptedAsAnswers } from '../../lib/methodsUtil'

const getPageLimitNumber = {
  beginner_additions: 5,
  easy_additions: 10,
  medium_additions: 100,
  hard_additions: 10000,
  beginner_subtractions: 5,
  easy_subtractions: 10,
  medium_subtractions: 100,
  hard_subtractions: 10000,
  beginner_multiplications: 4,
  easy_multiplications: 10,
  medium_multiplications: 20,
  hard_multiplications: 100,
  beginner_divisions: 5,
  easy_divisions: 10,
  medium_divisions: 100,
  hard_divisions: 1000
}

// first number is the max directory, second number is what we add to get there (making a bracket)
const getDisplayDirectoryLimits = {
  beginner_additions: [0, 1],
  easy_additions: [6, 1],
  medium_additions: [21, 7],
  hard_additions: [21, 7],
  beginner_subtractions: [0, 1],
  easy_subtractions: [6, 1],
  medium_subtractions: [21, 7],
  hard_subtractions: [21, 7],
  beginner_multiplications: [0, 1],
  easy_multiplications: [6, 1],
  medium_multiplications: [21, 7],
  hard_multiplications: [21, 7],
  beginner_divisions: [0, 1],
  easy_divisions: [6, 1],
  medium_divisions: [21, 7],
  hard_divisions: [21, 7]
}

const addNumbers = numbersArray => numbersArray[0] + numbersArray[1]
const subtractNumbers = numbersArray => numbersArray[0] - numbersArray[1]
const multiplyNumbers = numbersArray => numbersArray[0] * numbersArray[1]
const divideNumbers = numbersArray => Math.floor(numbersArray[0] / numbersArray[1])
const getDivisionRemainder = numbersArray => Math.floor(numbersArray[0] % numbersArray[1])

const calculateResult = (pagename, firstNumber, secondNumber) => {
  const pagenameSplit = pagename.split('_')
  const type =
    pagenameSplit.length === 3 ? `${pagenameSplit[1]}_${pagenameSplit[2]}` : pagenameSplit[1]

  const results = {
    additions: firstNumber + secondNumber,
    subtractions: firstNumber - secondNumber,
    multiplications: firstNumber * secondNumber,
    divisions_quotient: Math.floor(firstNumber / secondNumber),
    divisions_remainder: firstNumber % secondNumber
  }
  return results[type]
}

const getMathNumberImageUrl = (random, number) =>
  `${baseUrl}/images/mathematics/numbers/${random}/${number === '-' ? 'minus' : number}.webp`

const makeQuestionText = (lngCode, numbersArray, questionType) =>
  `${numbersArray[0]} ${getSignName[lngCode][questionType]} ${numbersArray[1]}`

const makeQuestionAnswer = answer => answer

const commonProps = {
  navigationCommands: ['mathematics', 'menu'],
  randomizeQuestionFunction: randomizeNumbersAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetMathematicsAction,
  getImageUrl: getMathNumberImageUrl
}

const gameTypeProps = ({
  lngCode,
  questionType,
  divisionType,
  questionLevel,
  numbersArray,
  result,
  resultsList
}) => {
  return {
    pageName: `${questionLevel}_${questionType}${divisionType ? `_${divisionType}` : ''}`,
    questionText: makeQuestionText(lngCode, numbersArray, questionType),
    questionAnswer: `${result}`,
    displayType: questionType,
    resultsList,
    alsoAcceptedAsAnswers: getMathematicsAlsoAcceptedAsAnswers(result, lngCode)
  }
}

export {
  addNumbers,
  subtractNumbers,
  multiplyNumbers,
  divideNumbers,
  getDivisionRemainder,
  calculateResult,
  commonProps,
  gameTypeProps,
  getPageLimitNumber,
  getDisplayDirectoryLimits
}
