import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

// SSS - Look at changing "letter f" to "it's F" and allow "f letter" as Aria was confused

const LettersAndNumbersPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['learn', 'play']}
    pageName="letters_and_numbers_page"
    customNavigationPath="/words/letters-and-numbers"
  />
)

export default LettersAndNumbersPage
