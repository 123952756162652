import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import '../styles/scss/App.scss'
import GCloudSpeechToTextTestButtons from '../components/GCloudSpeechToTextButtons'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { common } from '../lib/languagesUtils/languageUtil'
import { isConfirmedLoggedUser } from '../lib/methodsUtil'
import { wasPathChanged } from '../router/routerUtil'
import {
  changePathAction,
  updateIsListeningAction,
  getUserAction,
  updateIsUsingMicrophoneAction
} from '../redux/actions/actions'
import googleCloudSpeechToText from '../lib/speechToTextUtils/googleCloudSpeechToText'
import { blockGoogleSpeechToTextAPI } from '../lib/_processUtil'

class LogBoubouPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(updateIsListeningAction(false))
  }

  render() {
    const { dispatch, user, lngCode, language, isListening, transcript, path } = this.props
    return (
      <div className="speech-page top-navigation">
        <div>Fake Boubou Log page</div>
        <button
          className="fake-button-one"
          // when logging in, initializing the Google Cloud Speech-To-Text Singleton; the reason being that for security
          // reasons and unlike Annyang, the Google Speech API needs a user action like a click to start working.
          onClick={async () => {
            dispatch(getUserAction())
            dispatch(updateIsUsingMicrophoneAction(true))
            try {
              await googleCloudSpeechToText.initializeGoogleApiState({ language, dispatch })
            } catch (error) {
              console.error('Error on initializeGoogleApiState:', error)
            }
          }}>
          Log Boubou
        </button>
        <button className="fake-button-two" onClick={() => dispatch(changePathAction('/menu'))}>
          {common('menu')}
        </button>
        {isConfirmedLoggedUser(user) && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '5px',
              background: 'pink',
              border: '1px solid black',
              borderRadius: '4px',
              margin: '10px'
            }}>
            {user.name} logged in! 👍
          </div>
        )}
        <button
          style={{ zIndex: '999' }}
          className="fake-button-two"
          onClick={() => dispatch(changePathAction('/sign-up'))}>
          {common('sign_up')}
        </button>
        {blockGoogleSpeechToTextAPI ? (
          <div style={{ color: 'red', padding: '10px' }}>
            Google API is BLOCKED / Buttons hidden
          </div>
        ) : (
          <GCloudSpeechToTextTestButtons
            user={user}
            isListening={isListening}
            dispatch={dispatch}
            // check if it's language or lngCode
            language={language}
            lngCode={lngCode}
            navigationCommands={['capitals', 'flags', 'locations', 'menu']}
          />
        )}
        This is just a test page for Speech
        {transcript.length > 0 && (
          <div
            style={{
              marginTop: '25px',
              background: 'lightblue',
              border: '3px solid black',
              borderRadius: '5px',
              padding: '15px 25px'
            }}>
            {transcript[0]}
          </div>
        )}
        {wasPathChanged(path) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(
  connect(mapMinimalStateToProps, passDispatchToProps)(LogBoubouPage)
)
