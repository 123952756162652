import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const MathematicsPage = () => (
  <TopicNavigationPage
    navigationCommands={['additions', 'subtractions', 'multiplications', 'divisions']}
    pageName="mathematics_page"
    topicNavigationPath="mathematics"
    borderRadius={0.35}
    SEODescription="E=MC² - Empower Maths & Calculus² - Via speech commands & different levels, play & have fun with Additions, Subtractions, Multiplications and Divisions."
  />
)

export default MathematicsPage
