import actions from '../actions/action-types'

const initialState = {}

const mockLoggedUser = {
  name: 'Boubou',
  dob: '09/04/1980',
  nationality: 'FR',
  email: 'email@email.com',
  isEmailConfirmed: true,
  // NONE OF THE BELOW IS USED - Need a database
  hofConsent: false,
  hof: [{ name: 'capitals', date: '01/01/2023' }],
  plan: 'paidPlan', // several plans and names possible.
  premium: true, // if premium true, best plan, all is paid, access to ALL topics
  // ...else individual topics can be unlocked. path names to be used, example:
  // unlockedPremiumTopics: ['letters-and-numbers', 'multiplications', 'flags/hard']
  unlockedPremiumTopics: [] // nothing unlocked best as default value
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.getUser:
      return {
        ...state,
        // user: action.payload
        ...mockLoggedUser
      }

    case actions.setUser:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default userReducer
