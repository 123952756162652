import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { common } from '../lib/languagesUtils/languageUtil'
import { passDispatchToProps, mapIsAnnyangSupportedStateToProps } from '../redux/mapToPropsUtil'
import { changePathAction } from '../redux/actions/actions'

const ParentsInfoButton = ({ dispatch, isAnnyangSupported }) => {
  const [isInfoVisible, toggleIsInfoVisible] = useState('')

  const clickInfo = () => {
    const booleanValue = !!isInfoVisible
    toggleIsInfoVisible(!booleanValue)
  }

  return (
    isAnnyangSupported && (
      <>
        <div className="parents-info-button clickable" onClick={clickInfo}>
          {common(`parents_info_button_${isInfoVisible ? 'close' : 'open'}`)}
        </div>

        <div
          className={`parents-info-message parents-info-message_${
            isInfoVisible === '' ? 'hidden' : isInfoVisible ? 'opened' : 'closed'
          }`}>
          <div className="parents-info-explaination">{common('parents_info_button_message')}</div>
          <div
            className="parents-info-faq-link"
            style={{ display: isInfoVisible ? 'block' : 'none' }}
            onClick={() => dispatch(changePathAction('/questions#microphone_not_picking_up'))}>
            {common('parents_info_link_message')}
          </div>
        </div>
      </>
    )
  )
}

export default withTranslation()(
  connect(mapIsAnnyangSupportedStateToProps, passDispatchToProps)(ParentsInfoButton)
)
