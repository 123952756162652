import axios from 'axios'
import { createExtendedNavigationCommands } from './speechToTextMethodsUtil'
import {
  startAnnyangRecording,
  stopAnnyangRecording,
  resumeAnnyangRecording
} from './annyangSpeechToText'
import { changePathAction, saveTranscriptAction } from '../../redux/actions/actions'
import { isDevEnvironment } from '../_processUtil'

// The audio data must be less than 1 minute and under 10 MB is size.
// what was suggested (10 * 1024 * 1024 // 10 MB) didn't work. I made my own limit using trial and error:
// 8 * 8 * 1024 seem to be fine to avoid "PayloadTooLargeError: request entity too large" + messages are supposed to be short anyway
const maxSizeInBytes = 8 * 8 * 1024
const silenceThreshold = 0.01
const silenceTimeout = 1000 // 1 second of silence
const volumeThreshold = 0.06 // after a few tests, decided on this number.

class GoogleCloudSpeechToTextSingleton {
  constructor() {
    this.audioContext = null
    this.analyser = null
    this.dataArray = null
    this.mediaRecorder = null
    this.stream = null
    this.language = 'en_US'
    this.isGoogleAPIListening = false
    this.silenceDetected = false
    this.silenceTimer = null
    this.isForceStopRecording = false
    this.commands = {}
    this.startRecordingPromise = null // Promise cache
    this.shouldSendToAPI = false
  }

  _restartMediaRecorder() {
    if (this.mediaRecorder.state !== 'recording') {
      this.mediaRecorder.start()
    }
  }

  stopGoogleAPIRecording({ forceStopRecording = false }) {
    if (this.mediaRecorder.state === 'recording') {
      this.isGoogleAPIListening = false

      // if a stop recording button is pressed. Also no navigation is allowed on voice (commands = {})
      this.isForceStopRecording = forceStopRecording
      if (forceStopRecording) {
        this.commands = {}
      }

      this.mediaRecorder.stop()
    }
  }

  _handleSilence() {
    if (this.silenceDetected) return
    this.silenceDetected = true
    setTimeout(() => {
      if (this.silenceDetected && this.mediaRecorder.state === 'recording') {
        this.mediaRecorder.stop()
      }
    }, silenceTimeout)
  }

  _resetSilenceTimer() {
    clearTimeout(this.silenceTimer)
    this.silenceDetected = false
    this.silenceTimer = setTimeout(() => this._handleSilence.bind(this), silenceTimeout)
  }

  _checkSilence(dispatch) {
    if (this.analyser) {
      this.analyser.getByteTimeDomainData(this.dataArray)
      let sum = 0.0
      for (let i = 0; i < this.dataArray.length; i++) {
        const sample = this.dataArray[i] / 128.0 - 1.0 // Convert to [-1, 1] range
        sum += sample * sample
      }
      const volume = Math.sqrt(sum / this.dataArray.length)

      if (volume < silenceThreshold) {
        this._handleSilence(dispatch)
      } else {
        this._resetSilenceTimer()
      }

      if (this.isGoogleAPIListening) {
        requestAnimationFrame(() => this._checkSilence(dispatch))
      }
    }
  }

  async startGoogleAPIRecording({
    user,
    dispatch,
    navigationCommands = ['menu'],
    lngCode = 'en',
    autoRestart = true, // not really used or needed but just in case I want to disable one day
    temporarilyUnlocked = false
  }) {
    this.shouldSendToAPI = true
    console.log('****** GOOGLE API STARTING ********')
    // Reinitialize the AudioContext, AnalyserNode and connect to Node each time recording starts
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
    const source = this.audioContext.createMediaStreamSource(this.stream)
    this.analyser = this.audioContext.createAnalyser()
    this.analyser.fftSize = 2048
    this.dataArray = new Uint8Array(this.analyser.fftSize)
    source.connect(this.analyser)

    if (this.isForceStopRecording) {
      this.isForceStopRecording = false
    }
    // Prevent multiple starts
    if (this.isGoogleAPIListening || this.isForceStopRecording) {
      return
    }

    if (navigationCommands.length > 0) {
      this.commands = createExtendedNavigationCommands({
        user,
        navigationCommands,
        lngCode,
        temporarilyUnlocked
      })
    }

    // isGoogleAPIListening is only local to Google API (for silence detection)
    // Not clashing clash with isListening from the state / Annyang only.
    this.isGoogleAPIListening = true
    dispatch(saveTranscriptAction([]))
    this.silenceDetected = false
    this.silenceTimer = null

    this.mediaRecorder.onstop = () => {
      if (autoRestart && !this.isForceStopRecording) {
        setTimeout(() => {
          if (!this.isGoogleAPIListening) {
            this._restartMediaRecorder.bind(this)()
          }
          this._checkSilence(dispatch)
        }, 1000)
      }
    }

    this._restartMediaRecorder()
    this._checkSilence(dispatch)
  }

  // Function to convert audio blob to base64 encoded string
  _audioBlobToBase64(blob) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const arrayBuffer = reader.result
        const base64Audio = btoa(
          new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
        resolve(base64Audio)
      }
      // reader.onerror = reject
      reader.readAsArrayBuffer(blob)
    })
  }

  // If the volume level is below a certain threshold (whisper, blow, low level speaking), we do not want to send to the API
  async _isAcceptableAudioVolume(audioBlob) {
    return new Promise(resolve => {
      const tempAudioContext = new (window.AudioContext || window.webkitAudioContext)()
      const reader = new FileReader()
      reader.onload = async event => {
        tempAudioContext.decodeAudioData(event.target.result, audioBuffer => {
          const rawData = audioBuffer.getChannelData(0) // Get the audio samples
          let total = 0
          for (let i = 0; i < rawData.length; i++) {
            total += Math.abs(rawData[i])
          }
          const rms = Math.sqrt(total / rawData.length)
          resolve(rms > volumeThreshold)
        })
      }
      reader.readAsArrayBuffer(audioBlob)
    })
  }

  _isUnderPayloadSizeLimit(audioBlob) {
    return audioBlob.size < maxSizeInBytes
  }

  async _getTranscriptFromAPI(event, dispatch) {
    const audioBlob = event.data
    if (this.shouldSendToAPI && audioBlob && audioBlob.size > 0) {
      try {
        const isAcceptableAudioVolume = await this._isAcceptableAudioVolume(audioBlob)

        // Not 100% accurate but these limit sending crap to the API: limit unneeded billing + API errors
        const isLikelyValidAudio =
          isAcceptableAudioVolume && this._isUnderPayloadSizeLimit(audioBlob)
        if (isLikelyValidAudio) {
          const base64Audio = await this._audioBlobToBase64(audioBlob)

          try {
            await axios
              .post('/.netlify/functions/getSpeech', { language: this.language, base64Audio })
              .then(response => {
                if (response && response.data && response.data.transcript) {
                  const newText = response.data.transcript
                  if (isDevEnvironment) {
                    console.log('-------- Google API found: ', newText)
                  }
                  // Unlike Annyang that returns an array of strings, the Google API only returns 1 string
                  // the closest to what was guessed as spoken. We save in an array to use all the similar logics
                  dispatch(saveTranscriptAction([newText]))

                  // if a known navigation command is spoken, navigate to that page
                  if (Object.keys(this.commands).includes(newText)) {
                    dispatch(changePathAction(this.commands[newText]))
                  }
                }
              })
          } catch (error) {
            console.error('Error sending audio to API:', error)
          }
        }
      } catch (error) {
        console.error('Error handling audio transcript:', error)
      }
    }

    this._restartMediaRecorder()
    this._checkSilence()
  }

  async initializeGoogleApiState({ language = 'en_US', dispatch, isLanguageChange = false }) {
    if (this.mediaRecorder && this.stream && !isLanguageChange) {
      console.log('Already initialized!')
      return // Already initialized
    }

    this.language = language

    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true })

      if (!this.mediaRecorder || this.mediaRecorder.state === 'inactive') {
        this.mediaRecorder = new MediaRecorder(this.stream)
      }

      this.mediaRecorder.ondataavailable = event => {
        this._getTranscriptFromAPI(event, dispatch)
      }

      console.log('MediaRecorder initialized and ready!')
    } catch (error) {
      this.isGoogleAPIListening = false
      console.error('Error accessing media devices:', error)
    }
  }
}

const googleCloudSpeechToText = new GoogleCloudSpeechToTextSingleton()
let backUpCache
const startGoogleAPITimeout = 5000

const startGoogleAPIRecording = async ({
  user,
  dispatch,
  navigationCommands,
  lngCode,
  autoRestart,
  temporarilyUnlocked
}) => {
  const startGoogleAPIBackupRecording = () => {
    return setTimeout(async () => {
      try {
        await googleCloudSpeechToText.startGoogleAPIRecording({
          user,
          dispatch,
          navigationCommands,
          lngCode,
          autoRestart,
          temporarilyUnlocked
        })
      } catch (error) {
        console.error('Error on startGoogleAPIRecording:', error)
      }
    }, startGoogleAPITimeout)
  }

  startAnnyangRecording({
    user,
    dispatch,
    navigationCommands,
    lngCode,
    autoRestart,
    temporarilyUnlocked,
    onResultCallback: () => {
      // To clear all the timeouts
      // for (let i = 0; i < 10000; i++) { clearTimeout(i) }
      googleCloudSpeechToText.shouldSendToAPI = false
      clearTimeout(backUpCache)
      // Back-up re-start if nothing heard after 5 seconds, after Something was picked up by Annyang
      backUpCache = startGoogleAPIBackupRecording()
    }
  })

  // Back-up First start if nothing heard after 5 seconds / Annyang is way quicker!
  backUpCache = startGoogleAPIBackupRecording()
}

const resumeGoogleAPIRecording = async ({
  user,
  dispatch,
  navigationCommands,
  lngCode,
  autoRestart,
  temporarilyUnlocked
}) => {
  resumeAnnyangRecording({ dispatch })

  backUpCache = setTimeout(async () => {
    try {
      await googleCloudSpeechToText.startGoogleAPIRecording({
        user,
        dispatch,
        navigationCommands,
        lngCode,
        autoRestart,
        temporarilyUnlocked
      })
    } catch (error) {
      console.error('Error on startGoogleAPIRecording:', error)
    }
  }, startGoogleAPITimeout)
}

// Helper function to stop recording
const stopGoogleAPIRecording = async ({ dispatch, forceStopRecording = false }) => {
  stopAnnyangRecording({
    dispatch,
    onStopAnnyangCallback: () => {
      clearTimeout(backUpCache)
      googleCloudSpeechToText.shouldSendToAPI = false
    }
  })

  try {
    googleCloudSpeechToText.stopGoogleAPIRecording({
      forceStopRecording
    })
  } catch (error) {
    console.error('Error on stopGoogleAPIRecording:', error)
  }
}

export { startGoogleAPIRecording, resumeGoogleAPIRecording, stopGoogleAPIRecording }
export default googleCloudSpeechToText
