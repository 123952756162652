import React from 'react'
import LearnPage from '../../../components/LearnPage'
import { getLettersAndNumbersLearnImageUrl } from '../../../pages/words/letters-and-numbers/util'

const LearnLettersAndNumbersPage = () => (
  <LearnPage
    parentPage="letters_and_numbers"
    grandParentPage="words"
    pageName="learn_letters_and_numbers"
    getImageUrl={getLettersAndNumbersLearnImageUrl}
  />
)

export default LearnLettersAndNumbersPage
