import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'
import SocialSharing from '../components/SocialSharing'

const TemporarilyUnlockPage = () => (
  <TopicNavigationPage
    navigationCommands={['about', 'questions']}
    pageName="temporarily_unlock_page"
    borderRadius={0.45}
    SEODescription="E=MC² - Embrace More Challenge² - Unlock capitals game mode and share your geography learning experience via Mini-Einstein and its speech recognition tech">
    {() => <SocialSharing />}
  </TopicNavigationPage>
)

export default TemporarilyUnlockPage
