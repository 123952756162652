import { baseUrl } from '../../../lib/_processUtil'
import { createSameKeyValueObject, createInvertedAnswerQuestionMap } from '../../../lib/methodsUtil'
import { translatedQuestionsAndAnswers } from '../../../lib/languagesUtils/languageUtil'
import { randomizeAWordAction, resetWordsAction } from '../../../redux/actions/actions'

const answerQuestionImageIndexLimitMap = {
  almond: 1,
  apple: 9,
  aspargus: 0,
  aubergine: 4,
  avocado: 12,
  bacon: 6,
  banana: 6,
  beans: 8,
  beetroot: 0,
  biscuit: 5,
  bread: 23,
  broccoli: 7,
  brownie: 2,
  burger: 6,
  butter: 4,
  cake: 9,
  candy: 11,
  cantaloup: 0,
  carrot: 4,
  cauliflower: 3,
  cereals: 2,
  cheese: 11,
  cherry: 0,
  chicken: 15,
  chili: 1,
  chips: 6,
  chocolate: 11,
  coconut: 1,
  coffee: 5,
  cookie: 8,
  corn: 8,
  crisps: 1,
  croissant: 15,
  cucumber: 3,
  donught: 20,
  egg: 9,
  fish: 0,
  garlic: 2,
  grappes: 6,
  ham: 0,
  honey: 2,
  'hot-dog': 2,
  'hot-sauce': 3,
  ice: 8,
  'ice-cream': 26,
  ketchup: 4,
  leak: 0,
  lemon: 6,
  lettuce: 6,
  lollipop: 1,
  lolly: 21,
  milk: 17,
  muffin: 5,
  mushroom: 13,
  mussel: 2,
  mustard: 0,
  nacho: 2,
  oil: 7,
  omelette: 2,
  onion: 8,
  orange: 11,
  'orange-juice': 2,
  oyster: 2,
  pancake: 11,
  parsley: 1,
  pasta: 11,
  peach: 5,
  peanut: 4,
  pear: 8,
  pepper: 4,
  pie: 4,
  pineapple: 0,
  pistachio: 0,
  pizza: 12,
  popcorn: 6,
  potato: 5,
  pretzel: 9,
  pumpkin: 7,
  radish: 4,
  salmon: 0,
  salt: 0,
  sandwich: 8,
  sausage: 0,
  scallop: 1,
  shrimp: 1,
  soda: 7,
  soup: 4,
  spaghetti: 1,
  'spring-onion': 0,
  steak: 11,
  strawberry: 4,
  sushi: 4,
  tea: 4,
  tomato: 8,
  waffle: 5,
  walnut: 1,
  water: 6,
  watermelon: 8
}

const foodList = Object.keys(answerQuestionImageIndexLimitMap)

const questionAnswerMap = {
  en: createSameKeyValueObject(foodList),
  fr: {
    almond: 'amande',
    apple: 'pomme',
    aspargus: 'asperge',
    aubergine: 'aubergine',
    avocado: 'avocat',
    bacon: 'bacon',
    banana: 'banane',
    beans: 'haricots',
    beetroot: 'betterave',
    biscuit: 'biscuit',
    bread: 'pain',
    broccoli: 'brocoli',
    brownie: 'brownie',
    burger: 'burger',
    butter: 'beurre',
    cake: 'gâteau',
    candy: 'bonbon',
    cantaloup: 'melon',
    carrot: 'carotte',
    cauliflower: 'chou-fleur',
    cereals: 'céréales',
    cheese: 'fromage',
    cherry: 'cerise',
    chicken: 'poulet',
    chili: 'piment',
    chips: 'frites',
    chocolate: 'chocolat',
    coconut: 'noix de coco',
    coffee: 'café',
    cookie: 'cookie',
    corn: 'maïs',
    crisps: 'chips',
    croissant: 'croissant',
    cucumber: 'concombre',
    donught: 'donut',
    egg: 'oeuf',
    fish: 'poisson',
    garlic: 'ail',
    grappes: 'raisin',
    ham: 'jambon',
    honey: 'miel',
    'hot-dog': 'hot dog',
    'hot-sauce': 'sauce piquante',
    ice: 'glasse', // purpose mistake as 'glace' already taken and crashed the logic
    'ice-cream': 'glace',
    ketchup: 'ketchup',
    leak: 'poireau',
    lemon: 'citron',
    lettuce: 'laitue',
    lollipop: 'sucette',
    lolly: `glace á l'eau`,
    milk: 'lait',
    muffin: 'muffin',
    mushroom: 'champignon',
    mussel: 'moule',
    mustard: 'moutarde',
    nacho: 'nachos',
    oil: 'huile',
    omelette: 'omelette',
    onion: 'oignon',
    orange: 'orange',
    'orange-juice': `jus d'orange`,
    oyster: 'huitre',
    pancake: 'crêpe',
    parsley: 'persil',
    pasta: 'pâtes',
    peach: 'pêche',
    peanut: 'cacahuète',
    pear: 'poire',
    pepper: 'poivron',
    pie: 'tarte',
    pineapple: 'ananas',
    pistachio: 'pistache',
    pizza: 'pizza',
    popcorn: 'popcorn',
    potato: 'patate',
    pretzel: 'bretzel',
    pumpkin: 'citrouille',
    radish: 'radis',
    salmon: 'saumon',
    salt: 'sel',
    sandwich: 'sandwich',
    sausage: 'saucisse',
    scallop: 'coquille saint-jacques',
    shrimp: 'crevette',
    soda: 'soda',
    soup: 'soupe',
    spaghetti: 'spaghetti',
    'spring-onion': 'ciboule',
    steak: 'steak',
    strawberry: 'fraise',
    sushi: 'sushi',
    tea: 'thé',
    tomato: 'tomate',
    waffle: 'waffle',
    walnut: 'noix',
    water: 'eau',
    watermelon: 'pastèque'
  }
}

const answerQuestionMap = createInvertedAnswerQuestionMap(questionAnswerMap)

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.words.food

const makeQuestionText = (answer, lngCode = 'en') => questionText[lngCode](answer)

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const getFoodImageUrl = (food, randomNumber) =>
  `${baseUrl}/images/words/food/${food}/${randomNumber}.webp`

const commonProps = {
  navigationCommands: ['words', 'menu'],
  imageAlt: 'food',
  randomizeQuestionFunction: randomizeAWordAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetWordsAction,
  displayType: 'image',
  getImageUrl: getFoodImageUrl
}

const alsoAcceptedAsAnswersMap = {
  en: {
    candy: ['candies'],
    cherry: ['cherries'],
    'hot-dog': ['hot dog'],
    'hot-sauce': ['hot sauce'],
    'ice-cream': ['ice cream'],
    muffin: ['cupcake'],
    'orange-juice': ['orange juice'],
    soda: ['cola'],
    'spring-onion': ['spring onion']
  },
  fr: {
    beans: ['haricot'],
    ice: ['glace', 'glaçon'],
    lettuce: ['salade'],
    lollipop: ['glace'],
    nacho: ['nacho'],
    pasta: ['pâte'],
    peanut: ['arachide'],
    potato: ['pomme de terre', 'pommes de terre'],
    pumpkin: ['potiron'],
    scallop: ['coquilles saint-jacques'],
    'spring-onion': ['ciboulette']
  }
}

const gameTypeProps = ({ lngCode, questionType, food }) => {
  return {
    pageName: `${questionType}_page`,
    questionText: makeQuestionText(food, lngCode),
    questionAnswer: makeQuestionAnswer(food, lngCode),
    answerQuestionMap,
    alsoAcceptedAsAnswers: alsoAcceptedAsAnswersMap[lngCode]
  }
}

export {
  answerQuestionImageIndexLimitMap,
  foodList,
  questionAnswerMap,
  answerQuestionMap,
  commonProps,
  gameTypeProps,
  getFoodImageUrl
}
