import React from 'react'
import LearnPage from '../../../../components/LearnPage'
import { getSmallCountryImageUrl } from '../../../../pages/geography/locations/util'

const LearnOtherLocationsPage = () => (
  <LearnPage
    parentPage="locations"
    grandParentPage="geography"
    pageName="learn_other_locations"
    getImageUrl={getSmallCountryImageUrl}
  />
)

export default LearnOtherLocationsPage
