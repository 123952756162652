import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const WordsReadingPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['easy', 'medium', 'hard']}
    pageName="words_reading_page"
    customNavigationPath="/reading/read-words"
  />
)

export default WordsReadingPage
