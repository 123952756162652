import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const GeographyPage = () => (
  <TopicNavigationPage
    navigationCommands={['capitals', 'flags', 'locations', 'country_select']}
    pageName="geography_page"
    topicNavigationPath="geography"
    borderRadius={0.25}
    SEODescription="E=MC² - Explore Many Countries² - Speech Commanded Games: Easy, Medium & Hard levels. Play, Learn & have fun with Countries Flags, Capitals & Locations"
  />
)

export default GeographyPage
