import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const MenuPage = () => (
  <TopicNavigationPage
    navigationCommands={[
      'words',
      'mathematics',
      'geography',
      'about',
      'language',
      'support',
      'reading',
      'login'
      // 'hall_of_fame_page'
      // 'shop'
    ]}
    pageName="menu_page"
    defaultStyle={{ borderRadius: '45px / 170px 70px 204px' }}
    SEODescription="E=MC² - Enter Mini Curriculum² - From as young as 2, Children can speech command the app & play BY THEMSELVES games on Words, Maths, Geography, Reading..."
  />
)

export default MenuPage
