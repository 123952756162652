import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnDivisionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['beginner', 'easy', 'medium', 'divisions', 'mathematics']}
    pageName="learn_divisions_page"
    topicNavigationPath="mathematics/divisions/learn"
    borderRadius={0.82}
    canonicalPath="/mathematics"
  />
)

export default LearnDivisionsPage
