// import React, { lazy } from 'react'
import React, { Component } from 'react'
import ImageWithDimensions from './ImageWithDimensions'
import { getCorrectAnswerImageUrl } from '../lib/themeUtil'
import { imageInAnimations, imageAnimations } from '../lib/animationsUtil'
import { getRandomItemFromList } from '../lib/methodsUtil'

class CorrectAnswerImage extends Component {
  render() {
    const { winningImage } = this.props
    const InAnimation = getRandomItemFromList(imageInAnimations)
    const Animation = getRandomItemFromList(imageAnimations)

    return (
      <InAnimation duration={0.5}>
        <Animation duration={10}>
          <ImageWithDimensions
            className="correct-answer-image"
            alt="correct answer"
            src={getCorrectAnswerImageUrl(winningImage)}
          />
        </Animation>
      </InAnimation>
    )
  }
}

export default CorrectAnswerImage
