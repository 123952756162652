import { signInWithPopup } from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import {
  auth,
  db,
  googleProvider,
  facebookProvider,
  appleProvider,
  twitterProvider,
  microsoftProvider
} from '../database/firebase.config'

const userDataDefaultProperties = {
  isEmailConfirmed: false,
  name: null,
  dob: null, // to wish a birthday
  nationality: null,
  userImg: null,
  settings: {
    language: 'en-GB',
    lngCode: 'en',
    gender: 'man',
    soundLevel: 'on'
  },
  hasUsedFreeTrial: false,
  hofConsent: false,
  hof: [],
  plan: null,
  premium: false,
  unlockedPremiumTopics: [],
  achievements: [],
  chosenAchievementDesign: null,
  notes: ''
}

// SSS - Social login need a username to be defined in profile, mandatory

const socialSignUp = async (provider, callback, errorCallback, emailAlreadyInUseCallback) => {
  try {
    // SSS - signInWithRedirec is an option but it didn't work.
    // Cross-Origin-Opener-Policy policy would block the window.close call.
    // Need to try live in prod with me- domain being registered, else, remove social sign up
    // SSS - actually works on Incognito
    const createUser = await signInWithPopup(auth, provider)
    const user = createUser.user
    const user_email = user.email

    const emailRef = doc(db, 'emails', user_email)
    const emailDoc = await getDoc(emailRef)

    // If the email alredy exists, we block the registration
    if (emailDoc.exists()) {
      emailAlreadyInUseCallback()
      console.log('Sign in blocked: email already in use.')
      throw new Error('email_already_registered')
    }

    // Proceed with registration if email does not exist
    const user_id = user.uid
    const userDataRef = doc(db, 'users', user_id)
    const userDoc = await getDoc(userDataRef)

    let userData
    if (!userDoc.exists()) {
      userData = {
        userId: user_id,
        username: '', // SSS - need to sanitize / profanity library?
        email: user_email,
        ...userDataDefaultProperties
      }

      // adds in the emails collection so no other user can use that email
      await setDoc(emailRef, { email: user_email })

      callback(userDataRef, userData)
    }
  } catch (error) {
    errorCallback(error)
  }
}

// Google Sign Up
const googleSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(googleProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Facebook Sign Up
const facebookSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(facebookProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Apple Sign Up
const appleSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(appleProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Twitter (X) Sign Up
const twitterSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(twitterProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

// Microsoft Sign Up
const microsoftSignUp = (callback, errorCallback, emailAlreadyInUseCallback) => {
  return socialSignUp(microsoftProvider, callback, errorCallback, emailAlreadyInUseCallback)
}

export {
  userDataDefaultProperties,
  googleSignUp,
  facebookSignUp,
  appleSignUp,
  twitterSignUp,
  microsoftSignUp
}
