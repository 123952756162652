import React from 'react'
import ShowClue from './ShowClue'
import WorldMap from './WorldMap'
import {
  isColorPage,
  isLettersAndNumbersPage,
  isImageDrivenWordPage,
  isGeographyPage,
  isCapitalsPage,
  isFlagsPage,
  isLocationsPage,
  createBorderRadiusFrom,
  createGlobeBorderRadiusFrom
} from '../lib/methodsUtil'
import { getCountryMapForCityArgument } from '../lib/topicsUtils/geographyUtil'
import {
  isGlobeImage,
  isVerySmallCountry as isLocationImage
} from '../pages/geography/locations/util'
import ImageWithDimensions from './ImageWithDimensions'
import { isVerySmallCountry as isCapitalImage } from '../pages/geography/capitals/util'
import { answerQuestionMap as getCapitalFrom } from '../pages/geography/capitals/answerQuestionMap'

const LearnDisplay = ({
  pageName,
  actual,
  translatedActual,
  getImageUrl,
  randomNumber,
  mapFillColorOne,
  mapFillColorTwo
}) =>
  actual && (
    <>
      {isColorPage(pageName) && (
        <div
          className="learn-display-color"
          style={{ ...createBorderRadiusFrom(actual), background: actual }}></div>
      )}

      {isGeographyPage(pageName) && <ShowClue clue={translatedActual} isLearnDisplay />}

      {isCapitalsPage(pageName) &&
        !isCapitalImage(getCapitalFrom['en'][actual]) &&
        getCountryMapForCityArgument(actual, mapFillColorOne, mapFillColorTwo)}

      {getImageUrl && isImageDrivenWordPage(pageName) && (
        <div className="displayed-image-wrapper-words" style={createBorderRadiusFrom(actual)}>
          <ImageWithDimensions
            alt="learn-page-image"
            className={`learn-displayed-word-image ${
              isLettersAndNumbersPage(pageName) ? '' : 'flip-image'
            }`}
            src={getImageUrl(actual, randomNumber)}
          />
        </div>
      )}

      {getImageUrl &&
        ((isLocationsPage(pageName) && isLocationImage(actual)) ||
          (isCapitalsPage(pageName) && isCapitalImage(getCapitalFrom['en'][actual])) ||
          isFlagsPage(pageName)) && (
          <div className="displayed-image-wrapper-geography">
            <ImageWithDimensions
              alt="learn-page-image"
              style={
                isGlobeImage(actual, pageName)
                  ? createGlobeBorderRadiusFrom(actual)
                  : createBorderRadiusFrom(actual)
              }
              className={`displayed-image-geography ${
                isFlagsPage(pageName) ? 'learn-displayed-image-flags' : ''
              }`}
              src={getImageUrl(actual, randomNumber)}
            />
          </div>
        )}

      {isLocationsPage(pageName) && !isLocationImage(actual) && (
        <WorldMap countryName={actual} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
      )}
    </>
  )

export default LearnDisplay
