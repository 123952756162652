import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import '../styles/scss/App.scss'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { common } from '../lib/languagesUtils/languageUtil'
import { wasPathChanged } from '../router/routerUtil'
import { changePathAction, updateIsListeningAction } from '../redux/actions/actions'

// Delete account, also delete in the users and emails collection

class ProfilePage extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(updateIsListeningAction(false))
  }

  render() {
    const { dispatch, path, user } = this.props
    return user.isEmailConfirmed ? (
      <div className="speech-page top-navigation">
        <div>Fake Profile Page</div>
        <div>
          incl log out button for now / image and display name change / delete account button / link
          to shop / plan info / link to achievements page
        </div>
        <button className="fake-button-two" onClick={() => dispatch(changePathAction('/menu'))}>
          {common('menu')}
        </button>

        {wasPathChanged(path) && <Navigate to={path} replace />}
      </div>
    ) : (
      <div>NEED TO CONFIRM EMAIL</div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(ProfilePage))
