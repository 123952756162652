import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, readingMapStateToProps } from '../../../redux/mapToPropsUtil'
import { answerQuestionMap, commonProps, gameTypeProps } from './util'
import { getTranslatedAnswer } from '../../../lib/methodsUtil'

// like 1 syllab: the cat is black

const EasySentencesReadingPage = props => {
  const { lngCode = 'en', actual: enSentence, actualColor } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'easy_sentences_reading',
        word:
          lngCode === 'en'
            ? enSentence
            : getTranslatedAnswer(lngCode, answerQuestionMap, enSentence),
        actualColor
      })}
    />
  )
}

export default connect(readingMapStateToProps, passDispatchToProps)(EasySentencesReadingPage)
