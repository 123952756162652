import { baseUrl } from '../../../lib/_processUtil'
import { answerQuestionMap, alsoAcceptedAsAnswersMap } from './answerQuestionMap'
import { top30Countries } from '../../../lib/topicsUtils/geographyUtil'
import { isLocationsPage, convertCamelCaseArrayToTitleCases } from '../../../lib/methodsUtil'
import { translatedQuestionsAndAnswers } from '../../../lib/languagesUtils/languageUtil'
import { randomizeACountryAction, resetGeographyAction } from '../../../redux/actions/actions'

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.geography.locations

const locationsImagesPath = `${baseUrl}/images/geography/capitalsAndLocations`

const getSmallCountryImageUrl = country =>
  `${locationsImagesPath}/${answerQuestionMap.en[country]}.webp`

const allLocationsList = Object.keys(answerQuestionMap.en)

const makeQuestionText = (lngCode, multipleChoices) => questionText[lngCode](multipleChoices)

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const top30WhiteSpacesCountries = convertCamelCaseArrayToTitleCases(top30Countries)

const easyList = top30WhiteSpacesCountries.map(country =>
  country === 'England' ? 'United Kingdom' : country
)

// removes countries which are too small to be seen (only Singapore)
const easyLocationsList = easyList.filter(country => allLocationsList.includes(country))

const isEasyQuestion = enCountry => top30Countries.includes(enCountry)

const squareImagesCountries = [
  'Albania',
  'Andorra',
  'Barbados',
  'Belgium',
  'Belize',
  'Bhutan',
  'Bosnia',
  'Bulgaria',
  'Burundi',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Djibouti',
  'Dominican Republic',
  'El Salvador',
  'Estonia',
  'Gambia',
  'Gambia',
  'Guinea',
  'Guinea-Bissau',
  'Haiti',
  'Honduras',
  'Ireland',
  'Israel',
  'Kosovo',
  'Lebanon',
  'Lesotho',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malawi',
  'Malta',
  'Mauritius',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Nepal',
  'Netherlands',
  'North Korea',
  'Qatar',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Vincent and the Grenadines',
  'San Marino',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Suriname',
  'Swaziland',
  'Switzerland',
  'Taiwan',
  'Togo',
  'Vatican'
]

const globeImagesCountries = [
  'Antigua and Barbuda',
  'Armenia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Benin',
  'Brunei',
  'Cape Verde',
  'Comoros',
  'Costa Rica',
  'Dominica',
  'Equatorial Guinea',
  'Eritrea',
  'Fiji',
  'Gabon',
  'Georgia',
  'Grenada',
  'Guatemala',
  'Hungary',
  'Iceland',
  'Jamaica',
  'Jordan',
  'Kiribati',
  'Kuwait',
  'Latvia',
  'Liberia',
  'Macedonia',
  'Maldives',
  'Marshall Islands',
  'Micronesia',
  'Nauru',
  'New Zealand',
  'Nicaragua',
  'Palau',
  'Panama',
  'Portugal',
  'Saint Lucia',
  'Samoa',
  'São Tomé and Principe',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Solomon Islands',
  'South Korea',
  'Sri Lanka',
  'Tajikistan',
  'Timor-Leste',
  'Tonga',
  'Trinidad and Tobago',
  'Tuvalu',
  'United Arab Emirates',
  'Vanuatu'
]

const isGlobeImage = (country, pageName) =>
  isLocationsPage(pageName) && globeImagesCountries.includes(country)

const isVerySmallCountry = country =>
  [...squareImagesCountries, ...globeImagesCountries].includes(country)

const commonProps = {
  navigationCommands: ['geography', 'menu'],
  imageAlt: 'country location',
  randomizeQuestionFunction: randomizeACountryAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetGeographyAction
}

const gameTypeProps = ({
  lngCode,
  questionType,
  questionLevel,
  country,
  multipleChoices,
  enCountry
}) => {
  const isCountryTooSmallForMap = isVerySmallCountry(enCountry)

  const levelQuestionTextMap = {
    easy: makeQuestionText(lngCode, multipleChoices),
    medium: makeQuestionText(lngCode, isEasyQuestion(enCountry) ? undefined : multipleChoices),
    hard: makeQuestionText(lngCode)
  }

  return {
    pageName: `${questionLevel}_${questionType}`,
    questionText: levelQuestionTextMap[questionLevel],
    questionAnswer: makeQuestionAnswer(country, lngCode),
    answerQuestionMap,
    displayType: isCountryTooSmallForMap ? 'image' : 'worldMap',
    getImageUrl: isCountryTooSmallForMap && getSmallCountryImageUrl,
    alsoAcceptedAsAnswers: alsoAcceptedAsAnswersMap[lngCode]
  }
}

export {
  allLocationsList,
  easyLocationsList,
  commonProps,
  gameTypeProps,
  isGlobeImage,
  isVerySmallCountry,
  getSmallCountryImageUrl
}
