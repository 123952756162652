import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { common } from '../../lib/languagesUtils/languageUtil'

class LettersAndNumbersParentsInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classStatus: 'none'
    }
  }

  componentTimeout

  componentDidUpdate(prevProps) {
    const { isPlaying: prevIsPlaying } = prevProps
    const { isPlaying } = this.props

    if (!prevIsPlaying && isPlaying) {
      this.componentTimeout = setTimeout(() => this.setState({ classStatus: 'show' }), 30000)
    }
    if (prevIsPlaying && !isPlaying) {
      this.setState({ classStatus: 'hide' })
    }
  }

  render() {
    const { classStatus } = this.state

    return (
      <div
        className={`letters-and-numbers-parents-info-initial-state letters-and-numbers-parents-info-${classStatus}`}>
        <div className="letters-and-numbers-parents-info-lines-wrapper">
          <div className="letters-and-numbers-parents-info-title">
            {common('letters_and_numbers_parents_info_link_message_title')}
          </div>
          <div className="letters-and-numbers-parents-info-intro">
            {common('letters_and_numbers_parents_info_link_message_intro')}
          </div>
          <div className="letters-and-numbers-parents-info-letter">
            {common('letters_and_numbers_parents_info_link_message_letter')}
          </div>
          <div className="letters-and-numbers-parents-info-number">
            {common('letters_and_numbers_parents_info_link_message_number')}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(LettersAndNumbersParentsInfo)
