import React from 'react'
import StyledI18n from '../components/StyledI18n'

const PageHeader = ({ title, intro, instructions, titleOnly = false }) => (
  <>
    {title && <h3>{title}</h3>}
    {!titleOnly && intro}
    {!titleOnly && instructions ? (
      <ul>
        {instructions.map(instruction => (
          <li key={instruction}>
            <StyledI18n text={instruction} />
          </li>
        ))}
      </ul>
    ) : null}
  </>
)

export default PageHeader
