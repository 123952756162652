import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
// import '../styles/scss/App.scss'
import {
  startRecording,
  stopRecording,
  isUsingAnnyang
} from '../lib/speechToTextUtils/speechToText'
import {
  translateFrom,
  common,
  instructionTranslate,
  makeWhereIAmText
} from '../lib/languagesUtils/languageUtil'
import readOutLoud from '../lib/readOutLoud'
import { isReadOutLoudDisabled } from '../lib/_processUtil'
import {
  createMainNavigationCommands,
  createExtendedNavigationCommands
} from '../lib/speechToTextUtils/speechToTextMethodsUtil'
import {
  isRedirectButtonClick,
  isHelpRequest,
  isWhereAmIRequest,
  isRedirectPath,
  getIsNavigationSoundOff,
  createBorderRadius,
  isTranscriptAMatch,
  updateNavigationCommandsWithMenu,
  getParentTopicFromPath,
  isPaidUser
} from '../lib/methodsUtil'
import PageHeader from './PageHeader'
import SpeechButton from './SpeechButton'
import NavigationButtons from './NavigationButtons'
import FreeVersionBanner from './FreeVersionBanner'
import ReadOutLoudRoboticVoiceNotice from './ReadOutLoudRoboticVoiceNotice'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import AppListeningSpeakingVisual from './AppListeningSpeakingVisual'
import AnnyangNotSupportedMenuButton from './AnnyangNotSupportedMenuButton'
import { wasPathChanged } from '../router/routerUtil'

const createUpdatedNavCommands = (customNavigationPath, navigationCommands) => {
  const updatedCommands = [...navigationCommands, getParentTopicFromPath(customNavigationPath)]
  return updateNavigationCommandsWithMenu(updatedCommands)
}

class EasyMediumHardNavigationPage extends Component {
  constructor(props) {
    super(props)
    const { isChrome, isUsingMicrophone, soundLevel } = props
    this.state = {
      wasHelpOrWhereAmITriggeredAtLeastOnce: false,
      isRoboticVoice: !isChrome && isUsingMicrophone && soundLevel !== 'off'
    }
  }

  componentDidMount() {
    const {
      dispatch,
      user,
      lngCode,
      navigationCommands,
      customNavigationPath,
      temporarilyUnlocked
    } = this.props
    const updatedNavCommands = createUpdatedNavCommands(customNavigationPath, navigationCommands)
    startRecording(user)({
      user,
      dispatch,
      navigationCommands: updatedNavCommands,
      lngCode,
      temporarilyUnlocked
    })
  }

  shouldComponentUpdate(nextProps) {
    const {
      user,
      isListening,
      isRoboticVoiceNoticeVisible,
      navigationCommands,
      customNavigationPath,
      extraNavigationCommands,
      lngCode,
      temporarilyUnlocked
    } = this.props
    const { transcript: nextTranscript, path: nextPath, isListening: nextIsListening } = nextProps
    const { isRoboticVoice } = this.state

    const updatedNavCommands = createUpdatedNavCommands(customNavigationPath, navigationCommands)

    const translatedNavigationCommands = Object.keys({
      ...createExtendedNavigationCommands({
        user,
        navigationCommands: updatedNavCommands,
        lngCode,
        temporarilyUnlocked
      }),
      ...extraNavigationCommands
    })

    return (
      isTranscriptAMatch(nextTranscript, translatedNavigationCommands) ||
      isRedirectButtonClick(nextPath, updatedNavCommands) ||
      isRedirectPath(nextPath) ||
      isHelpRequest(nextTranscript) ||
      isWhereAmIRequest(nextTranscript, lngCode) ||
      (isRoboticVoice && isRoboticVoiceNoticeVisible && isListening !== nextIsListening)
    )
  }

  componentDidUpdate(prevProps) {
    const { isListening: prevIsListening } = prevProps
    const { isListening, isRoboticVoiceNoticeVisible } = this.props
    const { isRoboticVoice } = this.state

    if (isRoboticVoice && isRoboticVoiceNoticeVisible && prevIsListening && !isListening) {
      this.setState({ wasHelpOrWhereAmITriggeredAtLeastOnce: true })
    }
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  render() {
    const {
      user,
      path,
      dispatch,
      pageName,
      navigationCommands,
      customNavigationPath,
      extraNavigationCommands,
      transcript,
      language,
      lngCode,
      gender,
      soundLevel,
      isAnnyangSupported,
      isUsingMicrophone,
      isRoboticVoiceNoticeVisible,
      temporarilyUnlocked
    } = this.props
    const { wasHelpOrWhereAmITriggeredAtLeastOnce } = this.state

    const translate = translateFrom(pageName)
    const intro = translate('intro')
    const title = translate('title')

    const parentTopic = getParentTopicFromPath(customNavigationPath)
    const instructionsList = [...new Set([...navigationCommands, `back_to_${parentTopic}`, 'menu'])]
    const instructions = isUsingMicrophone
      ? instructionsList.map(level => instructionTranslate(level))
      : []

    const text = `${intro} ${instructions.join('. ')}`
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const updatedNavCommands = createUpdatedNavCommands(customNavigationPath, navigationCommands)
    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? text : makeWhereIAmText[lngCode]({ title }),
        dispatch,
        language,
        gender,
        navigationCommands: updatedNavCommands,
        user
      })
    }

    return (
      <div className={`${pageName.replace(/_/g, '-')} top-navigation`}>
        <div className="navigation-box" style={createBorderRadius(0.65)}>
          <PageHeader title={title} intro={intro} instructions={instructions} />

          <AppListeningSpeakingVisual />

          {!isAnnyangSupported && !isPaidUser(user) && (
            <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
          )}

          {isUsingAnnyang(user) && <FreeVersionBanner common={common} />}

          {wasHelpOrWhereAmITriggeredAtLeastOnce && isRoboticVoiceNoticeVisible && (
            <ReadOutLoudRoboticVoiceNotice dispatch={dispatch} common={common} />
          )}

          <NavigationButtons
            customNavigationCommands={{
              ...createMainNavigationCommands({
                user,
                navigationCommands,
                lngCode,
                temporarilyUnlocked
              }),
              ...{ [common(parentTopic).toLowerCase()]: `/${parentTopic}` },
              ...{ [common('menu').toLowerCase()]: '/menu' },
              ...extraNavigationCommands
            }}
            lngCode={lngCode}
            dispatch={dispatch}
          />
        </div>

        {!isNavigationSoundOff && (
          <SpeechButton
            className={`${pageName.replace(/_/g, '-')}-speech-button`}
            text={text}
            navigationCommands={updatedNavCommands}
          />
        )}

        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(EasyMediumHardNavigationPage)
