import { invertedAnswerQuestionMap, alsoAcceptedReverseAsAnswersMap } from '../answerQuestionMap'
import {
  getSmallCountryImageUrl,
  isVerySmallCountry,
  questionAnswerMap,
  isEasyQuestion
} from '../util'
import { translatedQuestionsAndAnswers } from '../../../../lib/languagesUtils/languageUtil'
import { randomizeACountryAction, resetGeographyAction } from '../../../../redux/actions/actions'

const { reverseQuestionText, reverseQuestionAnswer } =
  translatedQuestionsAndAnswers.geography.capitals

const makeReverseQuestionText = (capital, lngCode, multipleChoices) =>
  reverseQuestionText[lngCode](capital, multipleChoices)

const makeReverseQuestionAnswer = (answer, lngCode = 'en') =>
  reverseQuestionAnswer[lngCode](answer, invertedAnswerQuestionMap)

const reverseCommonProps = {
  navigationCommands: ['geography', 'menu'],
  imageAlt: 'reverse country capital',
  randomizeQuestionFunction: randomizeACountryAction,
  getPreviousQuestionAnswer: makeReverseQuestionAnswer,
  resetCategoryAction: resetGeographyAction
}

const reverseGameTypeProps = ({
  lngCode,
  questionType,
  questionLevel,
  country,
  capital,
  multipleChoices,
  enCountry
}) => {
  const isCountryTooSmallForMap = isVerySmallCountry(enCountry)

  const levelQuestionTextMap = {
    easy_reverse: makeReverseQuestionText(capital, lngCode, multipleChoices),
    medium_reverse: makeReverseQuestionText(
      capital,
      lngCode,
      isEasyQuestion(enCountry) ? undefined : multipleChoices
    ),
    hard_reverse: makeReverseQuestionText(capital, lngCode)
  }

  return {
    pageName: `${questionLevel}_${questionType}`,
    questionClue: capital,
    questionText: levelQuestionTextMap[questionLevel],
    questionAnswer: makeReverseQuestionAnswer(country, lngCode),
    answerQuestionMap: invertedAnswerQuestionMap,
    displayType: isCountryTooSmallForMap ? 'reverseImage' : 'reverseCountryMap',
    getImageUrl: isCountryTooSmallForMap && getSmallCountryImageUrl,
    alsoAcceptedAsAnswers: alsoAcceptedReverseAsAnswersMap[lngCode]
  }
}

export { questionAnswerMap, reverseCommonProps, reverseGameTypeProps }
