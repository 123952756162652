import { answerQuestionMap, alsoAcceptedAsAnswersMap } from './answerQuestionMap'
import { baseUrl } from '../../../lib/_processUtil'
import { top30Countries } from '../../../lib/topicsUtils/geographyUtil'
import { convertCamelCaseArrayToTitleCases } from '../../../lib/methodsUtil'
import { translatedQuestionsAndAnswers } from '../../../lib/languagesUtils/languageUtil'
import { randomizeACountryAction, resetGeographyAction } from '../../../redux/actions/actions'

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.geography.flags

const getCountryFlagImageUrl = country =>
  `${baseUrl}/images/geography/flags/${answerQuestionMap.en[country].toLowerCase()}.svg`

const allFlagsList = Object.keys(answerQuestionMap.en)

const makeQuestionText = (lngCode, multipleChoices) => questionText[lngCode](multipleChoices)

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const easyFlagsList = convertCamelCaseArrayToTitleCases(top30Countries)
easyFlagsList.push('United Kingdom')

const isEasyQuestion = enCountry => top30Countries.includes(enCountry)

const commonProps = {
  navigationCommands: ['geography', 'menu'],
  imageAlt: 'country flag',
  randomizeQuestionFunction: randomizeACountryAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetGeographyAction,
  displayType: 'image',
  getImageUrl: getCountryFlagImageUrl
}

const gameTypeProps = ({
  lngCode,
  questionType,
  questionLevel,
  country,
  multipleChoices,
  enCountry
}) => {
  const levelQuestionTextMap = {
    easy: makeQuestionText(lngCode, multipleChoices),
    medium: makeQuestionText(lngCode, isEasyQuestion(enCountry) ? undefined : multipleChoices),
    hard: makeQuestionText(lngCode)
  }

  return {
    pageName: `${questionLevel}_${questionType}`,
    questionText: levelQuestionTextMap[questionLevel],
    questionAnswer: makeQuestionAnswer(country, lngCode),
    answerQuestionMap,
    alsoAcceptedAsAnswers: alsoAcceptedAsAnswersMap[lngCode]
  }
}

export { easyFlagsList, allFlagsList, commonProps, gameTypeProps, getCountryFlagImageUrl }
