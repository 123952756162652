import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnCapitalsPage = () => (
  <TopicNavigationPage
    navigationCommands={['famous_ones', 'others', 'capitals', 'geography']}
    pageName="learn_capitals_page"
    topicNavigationPath="geography/capitals/learn"
    borderRadius={0.36}
    canonicalPath="/geography"
  />
)

export default LearnCapitalsPage
