import { unlockAllTopics } from './_processUtil'

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

const colors = [
  'red',
  'yellow',
  'blue',
  'green',
  'orange',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
  'white'
]

// These 2 methods are in dataUtil because initialised before methodsUtil, and needed in languageUtil
const createInvertedKeysValuesObject = keyValuesObject =>
  Object.keys(keyValuesObject).reduce((obj, key) => {
    obj[keyValuesObject[key]] = key
    return obj
  }, {})

const makeLowerCaseArray = arr => arr.map(word => word.toLowerCase())

const lightSvgFillColors = [
  'aquamarine',
  'goldenrod',
  'green',
  'lavender',
  'lightcyan',
  'lightgrey',
  'lightgreen',
  'lightpink',
  'lightskyblue',
  'lightyellow',
  'lime',
  'mistyrose',
  'papayawhip',
  'pink',
  'powderblue',
  'snow',
  'springgreen',
  'tan',
  'thistle',
  'wheat',
  'yellow',
  'yellowgreen'
]

const darkSvgFillColors = [
  'blue',
  'blueviolet',
  'brown',
  'burlywood',
  'chocolate',
  'darkgray',
  'darkgreen',
  'deeppink',
  'dodgerblue',
  'hotpink',
  'limegreen',
  'magenta',
  'olive',
  'orange',
  'orchid',
  'purple',
  'red',
  'skyblue',
  'slategrey',
  'teal',
  'turquoise'
]

const allSvgFillColors = [...lightSvgFillColors, ...darkSvgFillColors]

const pageTitleColorsMap = {
  // WORDS
  'words/colors': 'darkslateblue',
  'words/animals': 'yellow',
  'words/food': 'orangered',
  'words/letters-and-numbers': 'darkturquoise',
  // MATHEMATICS
  'mathematics/additions/beginner': 'red',
  'mathematics/additions/easy': 'orange',
  'mathematics/additions/medium': 'coral',
  'mathematics/additions/hard': 'indianred',
  'mathematics/subtractions/beginner': 'greenyellow',
  'mathematics/subtractions/easy': 'chartreuse',
  'mathematics/subtractions/medium': 'lime',
  'mathematics/subtractions/hard': 'palegreen',
  'mathematics/multiplications/beginner': 'mediumaquamarine',
  'mathematics/multiplications/easy': 'darkcyan',
  'mathematics/multiplications/medium': 'aqua',
  'mathematics/multiplications/hard': 'turquoise',
  'mathematics/divisions/beginner': 'lightseagreen',
  'mathematics/divisions/easy': 'olivedrab',
  'mathematics/divisions/medium': 'olive',
  'mathematics/divisions/hard': 'darkolivegreen',
  // GEOGRAPHY
  'geography/capitals/easy': 'mediumslateblue',
  'geography/capitals/medium': 'mediumspringgreen',
  'geography/capitals/hard': 'dodgerblue',
  'geography/capitals/reverse/easy': 'greenyellow',
  'geography/capitals/reverse/medium': 'limegreen',
  'geography/capitals/reverse/hard': 'lawngreen',
  'geography/flags/easy': 'hotpink',
  'geography/flags/medium': 'pink',
  'geography/flags/hard': 'deeppink',
  'geography/locations/easy': 'firebrick',
  'geography/locations/medium': 'sienna',
  'geography/locations/hard': 'crimson',
  // READING
  'reading/read-words/easy': 'lightskyblue',
  'reading/read-words/medium': 'powderblue',
  'reading/read-words/hard': 'deepskyblue',
  'reading/read-sentences/easy': 'mediumpurple',
  'reading/read-sentences/medium': 'blueviolet',
  'reading/read-sentences/hard': 'darkviolet'
}

const comingSoonTopics = [
  'country_select',
  'other_words',
  'read_sentences',
  'read_stories',
  'reading'
]

// using paths as references so with '-' instead of '_'
const premiumTopics = unlockAllTopics
  ? []
  : [
      'food',
      'letters-and-numbers',
      'subtractions',
      'multiplications',
      'divisions',
      'flags/medium',
      'flags/hard',
      'capitals/medium',
      'capitals/hard'
    ]

const needDonationsTopics = unlockAllTopics
  ? []
  : ['letters-and-numbers', 'multiplications', 'divisions', 'locations']

const newTopics = ['flags', 'locations', 'read_words', 'support']

const temporarilyUnlockTopics = unlockAllTopics ? [] : ['capitals']

export {
  letters,
  numbers,
  colors,
  createInvertedKeysValuesObject,
  makeLowerCaseArray,
  lightSvgFillColors,
  darkSvgFillColors,
  allSvgFillColors,
  pageTitleColorsMap,
  comingSoonTopics,
  premiumTopics,
  needDonationsTopics,
  newTopics,
  temporarilyUnlockTopics
}
