import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
// import '../styles/scss/App.scss'
import { isDevEnvironment, isReadOutLoudDisabled, minusIndexNumber } from '../lib/_processUtil'
import {
  startRecording,
  stopRecording,
  resumeRecording,
  isUsingAnnyang
} from '../lib/speechToTextUtils/speechToText'
import { common, makeWhereIAmText } from '../lib/languagesUtils/languageUtil'
import readOutLoud from '../lib/readOutLoud'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'
import {
  isMathematicsQuestion,
  getTranslatedAnswer,
  getQuestionAnswersBooleanValues,
  getSavedAnswerBeforeSeenListReset,
  isLettersAndNumbersPage,
  getIsNavigationSoundOff,
  getPageTitleColor,
  getTopic,
  isPaidUser
} from '../lib/methodsUtil'
import {
  changePathAction,
  resetForNextQuestionAction,
  isCorrectAnswerAction,
  isPlayingAction,
  getAWinningImageAction,
  saveTranscriptAction
} from '../redux/actions/actions'
import ShowAnswer from './ShowAnswer'
import FreeVersionBanner from './FreeVersionBanner'
import AnnyangNotSupportedMenuButton from './AnnyangNotSupportedMenuButton'
import AnnyangNotSupportedNextQuestionButton from './AnnyangNotSupportedNextQuestionButton'
import AnnyangNotSupportedShowAnswerButton from './AnnyangNotSupportedShowAnswerButton'
import CorrectAnswerImage from './CorrectAnswerImage'
import AnswerSound from './AnswerSound'
import WinningAnimation from './WinningAnimation'
// import CustomShapeAnimation from './CustomShapeAnimation'
import WrongAnswersCrosses from './WrongAnswersCrosses'
import PassComponent from './PassComponent'
import ShowClue from './ShowClue'
import QuestionDisplay from './QuestionDisplay'
import SpeechButton from './SpeechButton'
import ImageWithDimensions from './ImageWithDimensions'
import ReadOutLoudRoboticVoiceNotice from './ReadOutLoudRoboticVoiceNotice'
import AppListeningSpeakingVisual from './AppListeningSpeakingVisual'
import { wasPathChanged } from '../router/routerUtil'
import { getCorrectAnswerImageUrl } from '../lib/themeUtil'

class QuestionsAnswersGame extends Component {
  constructor(props) {
    super(props)
    const { isChrome, isUsingMicrophone, soundLevel } = props
    this.resetWrongAnswerList = this.resetWrongAnswerList.bind(this)
    this.handleAnnyangNotSupportedButtonNext = this.handleAnnyangNotSupportedButtonNext.bind(this)
    this.handleAnnyangNotSupportedShowAnswer = this.handleAnnyangNotSupportedShowAnswer.bind(this)
    this.toggleIsPassedValue = this.toggleIsPassedValue.bind(this)

    // The 3 below are only used in Dev mode with click on buttons  ------>
    this.handleDevCorrectClick = this.handleDevCorrectClick.bind(this)
    this.handleDevWrongAnswerClick = this.handleDevWrongAnswerClick.bind(this)
    this.handleDevRepeatClick = this.handleDevRepeatClick.bind(this)
    // <------

    this.state = {
      savedAnswerBeforeSeenListReset: '',
      wrongAnswersList: [],
      forceShowAnswer: false,
      isPassed: false,
      wasHelpOrWhereAmITriggeredAtLeastOnce: false,
      isRoboticVoice: !isChrome && isUsingMicrophone && soundLevel !== 'off'
    }
  }

  handleAnnyangNotSupportedButtonNext() {
    const { user, questionText, dispatch, language, gender, soundLevel, navigationCommands } =
      this.props
    this.resetWrongAnswerList()
    dispatch(resetForNextQuestionAction())
    dispatch(isPlayingAction(true))
    // On new question, for whatever reasons, the winningImage action is called twice.
    dispatch(getAWinningImageAction())
    isReadOutLoudDisabled || getIsNavigationSoundOff(soundLevel)
      ? null
      : readOutLoud({ text: questionText, dispatch, language, gender, navigationCommands, user })
  }

  handleAnnyangNotSupportedShowAnswer() {
    const { dispatch, pageName, randomizeQuestionFunction } = this.props
    dispatch(isPlayingAction(false))
    dispatch(randomizeQuestionFunction(pageName))
    this.setState({ forceShowAnswer: true })
  }

  // The 3 below are only used in Dev mode with click on buttons  ------>
  handleDevCorrectClick() {
    const {
      dispatch,
      user,
      pageName,
      randomizeQuestionFunction,
      navigationCommands,
      lngCode,
      language
    } = this.props
    dispatch(isCorrectAnswerAction())
    stopRecording(user)({ dispatch })
    dispatch(isPlayingAction(false))
    dispatch(randomizeQuestionFunction(pageName))
    resumeRecording(user)({ user, dispatch, navigationCommands, lngCode, language })
  }

  handleDevWrongAnswerClick() {
    this.setState({ wrongAnswersList: ['X', 'Y', 'Z'] })
  }

  handleDevRepeatClick() {
    const { user, questionText, dispatch, language, gender, soundLevel, navigationCommands } =
      this.props
    isReadOutLoudDisabled || getIsNavigationSoundOff(soundLevel)
      ? null
      : readOutLoud({
          text: questionText,
          dispatch,
          language,
          gender,
          clearTranscriptOnEnd: true,
          navigationCommands,
          user
        })
  }
  // <------

  componentDidMount() {
    const { dispatch, user, lngCode, navigationCommands, randomizeQuestionFunction, pageName } =
      this.props
    startRecording(user)({ user, dispatch, navigationCommands, lngCode })

    // choose a question on page mount
    dispatch(randomizeQuestionFunction(pageName))
  }

  componentDidUpdate(prevProps) {
    const { seenList: prevSeenList, transcript: prevTranscript } = prevProps
    const {
      transcript,
      isPlaying,
      actual,
      answerQuestionMap,
      alsoAcceptedAsAnswers,
      displayType,
      lngCode,
      seenList
    } = this.props

    // The logic showing the answer to "I don't know" use a -3 index from the seenList compared to its actual status. if the list has reset it cannot be accessed anymore.
    // As a workaround, on list reset, the last answer is saved in the state and this is how "I dont know" can access for that specific case onky.
    if (prevSeenList !== seenList) {
      this.setState({
        savedAnswerBeforeSeenListReset: getSavedAnswerBeforeSeenListReset(prevSeenList, seenList)
      })
    }

    // if we say the exact same answer (even if wrong) & annyang understands the exact same possibilities, the below will not run.
    const wasSomethingDifferentSaid = prevTranscript !== transcript && transcript.length > 0

    if (wasSomethingDifferentSaid) {
      const isMathematics = isMathematicsQuestion(displayType)
      const { isRepeat, isCorrect, isIDontKnow, nextQuestion, isPass, isWrong } =
        getQuestionAnswersBooleanValues(
          isMathematics,
          transcript,
          isPlaying,
          actual,
          answerQuestionMap,
          lngCode,
          alsoAcceptedAsAnswers
        )

      // React re-renders 5 or 6 times and as a result, if we have a wrong answer, we add what Annyang understood to the array 5 or 6 times.
      // Further down in the component, we use a unique filter to have it showing 1 cross per wrong answer
      const isAWrongAnswer =
        !nextQuestion && isPlaying && !isIDontKnow && !isCorrect && !isRepeat && !isPass && isWrong
      if (isAWrongAnswer) {
        const { wrongAnswersList } = this.state
        wrongAnswersList.push(prevTranscript.join())
        this.setState({ wrongAnswersList })
      }
    }
  }

  componentWillUnmount() {
    const { dispatch, user, resetCategoryAction } = this.props
    stopRecording(user)({ dispatch })
    dispatch(resetCategoryAction())
    dispatch(resetForNextQuestionAction())
    dispatch(isPlayingAction(false))
  }

  resetWrongAnswerList() {
    this.setState({ wrongAnswersList: [], forceShowAnswer: false })
  }

  toggleIsPassedValue() {
    const { isPassed } = this.state
    this.setState({ isPassed: !isPassed })
  }

  render() {
    const {
      user,
      path,
      pageName,
      isAnnyangSupported,
      isUsingMicrophone,
      language = 'en',
      lngCode = 'en',
      gender = 'man ',
      soundLevel = 'on',
      questionClue,
      questionText,
      questionAnswer,
      answerQuestionMap,
      displayType,
      getImageUrl,
      imageAlt,
      transcript,
      actual,
      seenList,
      isPlaying,
      isCorrectAnswer,
      dispatch,
      alsoAcceptedAsAnswers = {},
      randomizeQuestionFunction,
      getPreviousQuestionAnswer,
      winningImage,
      resultsList,
      randomList,
      randomNumber,
      mapFillColorOne,
      mapFillColorTwo,
      textDisplayColor,
      navigationCommands,
      isRoboticVoiceNoticeVisible
    } = this.props

    const titleLettersColor = getPageTitleColor(path) || 'ghostwhite'

    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const isSoundOff = soundLevel === 'off'
    const isReadOutLoudFullyDisabled = isReadOutLoudDisabled || isNavigationSoundOff

    const {
      savedAnswerBeforeSeenListReset,
      wrongAnswersList,
      forceShowAnswer,
      isPassed,
      isRoboticVoice,
      wasHelpOrWhereAmITriggeredAtLeastOnce
    } = this.state
    const isMathematics = isMathematicsQuestion(displayType)

    // Regarding minusIndexNumber, seenList.length < 3 seems to work for impair, but what about pair, might need -2?
    const previousExpected = isMathematics
      ? resultsList[resultsList.length - minusIndexNumber]
      : seenList.length < minusIndexNumber
        ? savedAnswerBeforeSeenListReset
        : seenList[seenList.length - minusIndexNumber]

    const previousAnswer =
      lngCode === 'en' || isMathematics || isLettersAndNumbersPage(pageName)
        ? previousExpected
        : getTranslatedAnswer(lngCode, answerQuestionMap, previousExpected)

    const { isHelp, isWhereAmI, isRepeat, isCorrect, isIDontKnow, nextQuestion, isPass } =
      getQuestionAnswersBooleanValues(
        isMathematics,
        transcript,
        isPlaying,
        actual,
        answerQuestionMap,
        lngCode,
        alsoAcceptedAsAnswers,
        questionAnswer
      )

    const getNewQuestionAndAnswer = () => {
      dispatch(isPlayingAction(false))
      dispatch(randomizeQuestionFunction(pageName))
    }

    const resetReduxStateForNextQuestion = () => {
      dispatch(resetForNextQuestionAction())
      dispatch(isPlayingAction(true))
      // On new question, for whatever reasons, the winningImage action is called twice.
      dispatch(getAWinningImageAction())
      sendAmplitudeEvent('Game Page Plays', { topic: getTopic(pageName), action: 'vocal' })
    }

    const commonReadOutLoudProps = {
      dispatch,
      language,
      gender,
      clearTranscriptOnEnd: true,
      navigationCommands,
      user
    }

    if (!isPlaying && (isHelp || isWhereAmI)) {
      const callBack = () => {
        if (isRoboticVoice && isRoboticVoiceNoticeVisible) {
          this.setState({ wasHelpOrWhereAmITriggeredAtLeastOnce: true })
        }
      }

      isReadOutLoudFullyDisabled
        ? null
        : readOutLoud({
            text: isHelp
              ? common('notPlayingSpeech')
              : makeWhereIAmText[lngCode]({ title: common(pageName), isGamePage: true }),
            callBack,
            ...commonReadOutLoudProps
          })
    }

    if (isPassed) {
      isReadOutLoudFullyDisabled
        ? null
        : readOutLoud({ text: questionText, ...commonReadOutLoudProps })
      this.toggleIsPassedValue()
    }

    if (nextQuestion && !isPlaying) {
      this.resetWrongAnswerList()
      resetReduxStateForNextQuestion()
      isReadOutLoudFullyDisabled
        ? null
        : readOutLoud({ text: questionText, dispatch, language, gender, navigationCommands, user })
    }

    if (isPlaying && !nextQuestion) {
      if (isRepeat) {
        isReadOutLoudFullyDisabled
          ? null
          : readOutLoud({ text: questionText, ...commonReadOutLoudProps })
        dispatch(saveTranscriptAction([]))
      } else if (isCorrect) {
        dispatch(isCorrectAnswerAction())
        stopRecording(user)({ dispatch })
        getNewQuestionAndAnswer()
        resumeRecording(user)({ user, dispatch })
      } else if (isIDontKnow) {
        isReadOutLoudDisabled || isSoundOff
          ? null
          : readOutLoud({
              text: questionAnswer,
              ...commonReadOutLoudProps,
              clearTranscriptOnEnd: false
            })
        getNewQuestionAndAnswer()
      }
    }

    // eslint-disable-next-line
    const uniqueWrongAnswersList = [...new Set(wrongAnswersList)].map(x => 'X')

    // Tested on Firefox - Only used for non-Chrome / Annyang not supported. nb: resultsList means it's mathematics.
    const annyangNotSupportedShownAnswer = resultsList
      ? resultsList[resultsList.length - 2]
      : seenList
        ? getTranslatedAnswer(lngCode, answerQuestionMap, seenList[seenList.length - 2])
        : ''

    return isPlaying && isPass ? (
      <PassComponent
        getNewQuestionAndAnswer={getNewQuestionAndAnswer}
        resetWrongAnswerList={this.resetWrongAnswerList}
        resetReduxStateForNextQuestion={resetReduxStateForNextQuestion}
        toggleIsPassedValue={this.toggleIsPassedValue}
      />
    ) : (
      <div
        className={`${pageName.replace(/_/g, '-')}-page${isPlaying ? ' is-playing' : ''} ${
          isMathematics ? `question-answer-game-${pageName.split('_')[1]}` : ''
        }
        question-answer-game`}>
        {!isPlaying && (
          <div className="question-answer-game-page-title" style={{ color: titleLettersColor }}>
            {common(pageName)}
          </div>
        )}

        {(isIDontKnow || forceShowAnswer) && (
          <ShowAnswer
            answer={
              forceShowAnswer
                ? annyangNotSupportedShownAnswer
                : getPreviousQuestionAnswer(previousAnswer, lngCode)
            }
            isMathematics={isMathematics}
            getImageUrl={getImageUrl}
            randomList={randomList}
            pageName={pageName}
            titleLettersColor={titleLettersColor}
            isUsingMicrophone={isUsingMicrophone}
          />
        )}

        {isCorrectAnswer && (
          <>
            <CorrectAnswerImage winningImage={winningImage} />
            {!isSoundOff && <AnswerSound isCorrect />}
            <WinningAnimation />
          </>
        )}

        {/* <CustomShapeAnimation /> */}

        {isPlaying && questionClue && (
          <ShowClue
            clue={questionClue}
            color={titleLettersColor}
            isRepeat={isRepeat}
            hasAnswers={uniqueWrongAnswersList.length > 0}
          />
        )}

        {isPlaying && !isCorrectAnswer && (
          <ImageWithDimensions
            className="none"
            alt="hidden preloaded answer image"
            src={getCorrectAnswerImageUrl(winningImage)}
          />
        )}

        {isPlaying && (
          <QuestionDisplay
            displayType={displayType}
            actual={actual}
            getImageUrl={getImageUrl}
            imageAlt={imageAlt}
            pageName={pageName}
            randomList={randomList}
            randomNumber={randomNumber}
            mapFillColorOne={mapFillColorOne}
            mapFillColorTwo={mapFillColorTwo}
            textDisplayColor={textDisplayColor}
            translatedActual={getTranslatedAnswer(lngCode, answerQuestionMap, actual)}
          />
        )}

        {isPlaying && !isCorrectAnswer && (
          <WrongAnswersCrosses wrongAnswersList={uniqueWrongAnswersList} />
        )}

        {!isAnnyangSupported &&
          !isPaidUser(user) &&
          (isPlaying ? (
            <AnnyangNotSupportedShowAnswerButton
              common={common}
              onClick={this.handleAnnyangNotSupportedShowAnswer}
            />
          ) : (
            <AnnyangNotSupportedNextQuestionButton
              common={common}
              onClick={this.handleAnnyangNotSupportedButtonNext}
            />
          ))}

        {/* ONLY VISIBLE FOR DEVS */}
        {isDevEnvironment && (
          <div className="question-answer-navigation-buttons">
            <button onClick={() => dispatch(changePathAction('/menu'))}>MENU</button>
            <button onClick={this.handleDevCorrectClick}>CORRECT ANSWER</button>
            <button onClick={this.handleAnnyangNotSupportedShowAnswer}>I DONT KNOW</button>
            <button onClick={this.handleAnnyangNotSupportedButtonNext}>NEXT</button>
            <button onClick={this.handleDevWrongAnswerClick}>WRONG</button>
            <button onClick={this.handleDevRepeatClick}>REPEAT</button>
          </div>
        )}

        {!isNavigationSoundOff && (
          <SpeechButton
            text={common(isPlaying ? 'playingSpeech' : 'notPlayingSpeech')}
            language={language}
            transcript={transcript}
            count={isPlaying ? 60 : isHelp || isWhereAmI ? 11 : 10}
            disableHelpAndWhereAmIRequestWhilePlaying={isPlaying || (!isPlaying && !nextQuestion)}
            navigationCommands={navigationCommands}
          />
        )}

        <AppListeningSpeakingVisual />

        {!isAnnyangSupported && !isPaidUser(user) && (
          <AnnyangNotSupportedMenuButton dispatch={dispatch} common={common} />
        )}

        {isUsingAnnyang(user) && <FreeVersionBanner common={common} />}

        {wasHelpOrWhereAmITriggeredAtLeastOnce && isRoboticVoiceNoticeVisible && (
          <ReadOutLoudRoboticVoiceNotice dispatch={dispatch} common={common} />
        )}

        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(QuestionsAnswersGame)
