import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnLocationsPage = () => (
  <TopicNavigationPage
    navigationCommands={['famous_ones', 'others', 'locations', 'geography']}
    pageName="learn_locations_page"
    topicNavigationPath="geography/locations/learn"
    borderRadius={0.63}
    canonicalPath="/geography"
  />
)

export default LearnLocationsPage
