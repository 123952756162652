import React, { Suspense, Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
// Moment.js locale files need to be imported explicitly.
import 'moment/locale/fr'
import '@fontsource/luckiest-guy'
import '@fontsource/orbitron'
import '@fontsource/kalam'
import { startRecording, stopRecording } from './lib/speechToTextUtils/speechToText'
import {
  translateFrom,
  common,
  instructionTranslate,
  makeWhereIAmText
} from './lib/languagesUtils/languageUtil'
import readOutLoud from './lib/readOutLoud'
import { baseUrl, isReadOutLoudDisabled, lastUpdatedDate } from './lib/_processUtil'
import {
  isHelpRequest,
  isWhereAmIRequest,
  getIsNavigationSoundOff,
  createBorderRadius
} from './lib/methodsUtil'
import { passDispatchToProps, mapMinimalStateToProps } from './redux/mapToPropsUtil'
import { changePathAction } from './redux/actions/actions'
import SEO from './components/SEO'
import StyledI18n from './components/StyledI18n'
import ImageWithDimensions from './components/ImageWithDimensions'
import ReadOutLoudRoboticVoiceNotice from './components/ReadOutLoudRoboticVoiceNotice'
import { wasPathChanged } from './router/routerUtil'

const SpeechButton = React.lazy(() => import('./components/SpeechButton'))
const ParentsInfoButton = React.lazy(() => import('./components/ParentsInfoButton'))
// const Footer = React.lazy(() => import('./components/Footer'))
const AppListeningSpeakingVisual = React.lazy(
  () => import('./components/AppListeningSpeakingVisual')
)

const translate = translateFrom('home_page')

class Home extends Component {
  constructor(props) {
    super(props)
    const { isChrome, isUsingMicrophone, soundLevel } = props
    this.state = {
      wasHelpOrWhereAmITriggeredAtLeastOnce: false,
      isRoboticVoice: !isChrome && isUsingMicrophone && soundLevel !== 'off'
    }
  }

  componentDidMount() {
    const { dispatch, user, lngCode } = this.props
    startRecording(user)({ user, dispatch, lngCode })
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props
    stopRecording(user)({ dispatch })
  }

  render() {
    const {
      user,
      isUsingMicrophone,
      isRoboticVoiceNoticeVisible,
      path,
      dispatch,
      transcript,
      lngCode,
      language,
      gender,
      soundLevel
    } = this.props
    const { wasHelpOrWhereAmITriggeredAtLeastOnce, isRoboticVoice } = this.state

    const supportedState = isUsingMicrophone
      ? instructionTranslate('menu_home')
      : common('voice_recognition_not_supported')

    const title = translate('title')
    const slogan = translate('slogan')

    const text = `${title}! ${slogan}! ${supportedState}`
    const isNavigationSoundOff = getIsNavigationSoundOff(soundLevel)
    const callBack = () => {
      if (isRoboticVoice && isRoboticVoiceNoticeVisible) {
        this.setState({ wasHelpOrWhereAmITriggeredAtLeastOnce: true })
      }
    }

    const isHelp = isHelpRequest(transcript)
    const isWhereAmI = isWhereAmIRequest(transcript, lngCode)
    if ((isHelp || isWhereAmI) && !isNavigationSoundOff && !isReadOutLoudDisabled) {
      readOutLoud({
        text: isHelp ? text : makeWhereIAmText[lngCode]({ title: common('home') }),
        dispatch,
        language,
        gender,
        user,
        callBack
      })
    }

    return (
      <div className="home-wrapper">
        <SEO title="Where geniuses are made!" path={path} />
        {!isNavigationSoundOff && (
          <Suspense fallback={<div>Loading...</div>}>
            <SpeechButton
              className="home-speech-button"
              text={text}
              readOutLoudCallBack={callBack}
            />
          </Suspense>
        )}

        <Suspense fallback={<div>Loading...</div>}>
          <AppListeningSpeakingVisual />
        </Suspense>

        {wasHelpOrWhereAmITriggeredAtLeastOnce && isRoboticVoiceNoticeVisible && (
          <ReadOutLoudRoboticVoiceNotice dispatch={dispatch} common={common} />
        )}

        <div className="navigation-box home-box" style={createBorderRadius(0.75)}>
          <div className="home-box-header">
            <div className="home-box-header-title">{title}</div>
            <div className="home-box-header-slogan">...{slogan}.</div>
          </div>
          <ImageWithDimensions
            alt="mini Einstein menu"
            className="mini-einstein-menu-picture"
            src={`${baseUrl}/images/mini-einstein-menu-image.webp`}
            // srcSet={`${baseUrl}/images/mini-einstein-menu-image.webp 1x, ${baseUrl}/images/mini-einstein-menu-image.webp 2x, ${baseUrl}/images/mini-einstein-menu-image.webp 3x`}
          />
          <div className="home-box-supported-state">
            <StyledI18n text={supportedState} />
          </div>
          <button
            style={createBorderRadius(0.5)}
            className="home-box-button"
            onClick={() => dispatch(changePathAction('/menu'))}>
            {common('menu')}
          </button>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
          <ParentsInfoButton />
        </Suspense>

        {wasPathChanged(path, 'home_page') && <Navigate to={path} replace />}

        {/* <Suspense fallback={<div>Footer Loading...</div>}>
          <Footer />
        </Suspense> */}

        <div className="home-last-updated">
          {common('last-updated')}: {moment(lastUpdatedDate).locale(lngCode).format('MMMM YYYY')}
        </div>
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(Home))
