import { isLetter } from '../methodsUtil'

const addEnAAn = word => (['a', 'e', 'i', 'o'].includes(word[0].toLowerCase()) ? 'an' : 'a')

const enFoodQuestionSentence = food => {
  if (
    [
      'apple',
      'avocado',
      'banana',
      'cantaloup',
      'cherry',
      'chili',
      'coconut',
      'grappes',
      'lemon',
      'orange',
      'peach',
      'pear',
      'pineapple',
      'pumpkin',
      'strawberry',
      'tomato',
      'walnut',
      'watermelon'
    ].includes(food)
  ) {
    return 'what is this fruit?'
  }
  if (
    [
      'aspargus',
      'aubergine',
      'beetroot',
      'broccoli',
      'carrot',
      'cauliflower',
      'corn',
      'cucumber',
      'garlic',
      'leak',
      'lettuce',
      'mushroom',
      'onion',
      'parsley',
      'pepper',
      'potato',
      'radish',
      'spring-onion'
    ].includes(food)
  ) {
    return 'what is this vegetable?'
  }
  if (['coffee', 'milk', 'orange-juice', 'soda', 'tea', 'water'].includes(food)) {
    return 'what is this drink?'
  }
  return 'what is this food?'
}

const noPrepositionFoodList = [
  'bacon',
  'butter',
  'cereals',
  'cheese',
  'chicken',
  'chocolate',
  'coffee',
  'corn',
  'garlic',
  'ham',
  'honey',
  'hot-sauce',
  'ice',
  'ketchup',
  'milk',
  'mustard',
  'oil',
  'orange-juice',
  'parsley',
  'pasta',
  'popcorn',
  'salmon',
  'salt',
  'spaghetti',
  'tea',
  'water'
]

const pluralFoodList = ['beans', 'chips', 'crisps', 'grappes']

const enFoodAnswerSentence = food => {
  if (food) {
    if (noPrepositionFoodList.includes(food)) {
      return `It's `
    }
    if (pluralFoodList.includes(food)) {
      return `These are `
    }
    return `It's ${addEnAAn(food)}`
  }
}

const enLettersAndNumbersQuestionSentence = letterAndNumber =>
  isLetter(letterAndNumber) ? 'What is this letter?' : 'What is this number'

const enLettersAndNumbersAnswerSentence = letterAndNumber =>
  isLetter(letterAndNumber)
    ? `It's the letter :${letterAndNumber.toUpperCase()}`
    : `It's the number :${letterAndNumber}`

export {
  addEnAAn,
  enFoodQuestionSentence,
  enFoodAnswerSentence,
  enLettersAndNumbersQuestionSentence,
  enLettersAndNumbersAnswerSentence
}
