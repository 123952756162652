import annyang from 'annyang'
import { browserName } from 'react-device-detect'
import { createExtendedNavigationCommands } from './speechToTextMethodsUtil'
import {
  changePathAction,
  saveTranscriptAction,
  updateIsListeningAction
} from '../../redux/actions/actions'
import { isDevEnvironment } from '../_processUtil'

// Annyang works best on Chromium-based browsers: Google Chrome, Microsoft Edge, Opera. Safari & Samsung Internet / Not Mozilla Firefox & IE
const isAnnyangSupported = () =>
  annyang !== null &&
  ['Chrome', 'Opera', 'Edge', 'Safari', 'MobileSafari', 'SamsungBrowser'].includes(browserName)

let commands = {}
// language is also passed as a prop in Google Cloud Speech but not needed in Annyang
const startAnnyangRecording = ({
  user = {},
  dispatch,
  navigationCommands = ['menu'],
  lngCode = 'en',
  autoRestart = true,
  temporarilyUnlocked,
  onResultCallback = () => {}
}) => {
  if (annyang) {
    if (navigationCommands.length > 0) {
      commands = createExtendedNavigationCommands({
        user,
        navigationCommands,
        lngCode,
        temporarilyUnlocked
      })
    }
    annyang.start({ autoRestart, continuous: false }) // these options are to make speech recognition results return faster
    annyang.addCallback('result', transcripts => {
      onResultCallback()
      if (isDevEnvironment) {
        console.log('--------- Annyang found: ', transcripts)
      }
      dispatch(saveTranscriptAction(transcripts.map(ts => ts.trim())))

      transcripts.forEach(transcript => {
        if (Object.keys(commands).includes(transcript)) {
          dispatch(changePathAction(commands[transcript]))
        }
      })
    })
    dispatch(updateIsListeningAction(true))
  }
}

const stopAnnyangRecording = ({ dispatch, onStopAnnyangCallback = () => {} }) => {
  if (annyang) {
    onStopAnnyangCallback()
    annyang.abort()
    dispatch(updateIsListeningAction(false))
  }
}

const resumeAnnyangRecording = ({ dispatch }) => {
  if (annyang) {
    annyang.resume()
    dispatch(updateIsListeningAction(true))
  }
}

const setAnnyangLanguage = language => {
  if (annyang) {
    annyang.setLanguage(language)
  }
}

export {
  isAnnyangSupported,
  startAnnyangRecording,
  stopAnnyangRecording,
  resumeAnnyangRecording,
  setAnnyangLanguage
}
