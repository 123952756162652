import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const content = translate => (
  <div className="content">
    <iframe
      src="https://www.youtube.com/embed/uNNlTvviUZc"
      title="About Mini Einstein"
      className="promotional-video"
    />

    <div className="content-attribution">
      <div className="content-attribution-title">{translate('external_credits')}:</div>
      {translate('design')}
      <a href="http://www.freepik.com" target="blank">
        {' '}
        brgfx / Freepik
      </a>
      . {translate('geography_design')}{' '}
      <a href="https://www.amcharts.com/svg-maps" target="blank">
        AmCharts
      </a>
      (CC BY-NC 4.0 License) &{' '}
      <a href="https://www.wikipedia.org/" target="blank">
        Wikipedia
      </a>
      .
    </div>
  </div>
)

const AboutPage = () => (
  <TopicNavigationPage
    navigationCommands={['questions', 'settings', 'contact']}
    pageName="about_page"
    borderRadius={0.45}
    SEODescription="E=MC² - Entertainers, Makers & Creators² - Discover the Story, how the application came together/was created & designed via Videos, introductions and History">
    {content}
  </TopicNavigationPage>
)

export default AboutPage
