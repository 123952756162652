import React from 'react'
import EasyMediumHardNavigationPage from '../../components/EasyMediumHardNavigationPage'

const AnimalsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['learn', 'play']}
    pageName="animals_page"
    customNavigationPath="/words/animals"
  />
)

export default AnimalsPage
